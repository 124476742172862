.centerColumn {
	.centerRight {
		.news-teaser {
			display: inline-block;
    width: calc(50% - 40px);
    vertical-align: top;
    border-bottom: 5px solid #d92240;
    padding: 0 0 10px 0;
    margin: 0 20px 80px 20px;

    		&:hover{
				.newsHolderImage {
					.bgImg{
						@include transform($center scale(1.2) !important);
					}
				}
    		}
			&:nth-child(odd) {
				margin-right:10px;
			}
			&:nth-child(even) {
				margin-left:10px;
			}
			h3 .galleryIcon {
				width:30px;
				margin-left:30px;
			}
			.newsHolderImage {
				display:inline-block;
				width:100%;
				    height: 300px;
				margin:0 0 20px 0;
				background-size:cover;
				background-position: center center;
				background-repeat: no-repeat;
				position: relative;
				overflow: hidden;

				.bgImg{
					position: absolute;
					top: 50%;
					left: 50%;
					@include transform($center scale(1));
					@include transition($t1);
					width: 100%;
					height: 100%;
					background-size:cover;
					background-position: center center;
					background-repeat: no-repeat;
				}

				.galleryIcon {
					height:20px;
					width:20px;
					position: absolute;
					right:10px;
					top:10px;
					svg {
						path {
							fill:$veryLightGrey;
							@include transition($t1)
						}
					}
				}
				&:hover {
					.galleryIcon {
						svg {
							path {
								fill:$darkBlue;
							}
						}
					}
				}
			}
			.newsHolderText {
				.articleTitle {
					color:$darkBlue;    
					font-size: 20px;
					margin-bottom:20px;
					display:block;
					height:56px;
				}
				.articleContent {
					height:45px;
					margin-bottom:40px;
					color:$textGrey;
					font-weight:100;
				}
				.fullStory {
					display:inline-block;
					text-transform: uppercase;
					font-size:14px;
					color:$LinkColor;
				}
			}
			.readMore {
				color:$LinkColor;
				&:hover {
					color:$LinkColor;
				}
			}
		}
		.contentWrapper {
			margin-bottom:60px;
		}
		

	}
	
}