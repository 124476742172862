.leftColumn {
	display: block;
}

.centerColumn {
	width:100%;
	max-width:1500px;
	margin:0 auto;
	display:flex;
	z-index: 2;
    position: relative;
    min-height: 30vh;
	ul li {
		list-style-type: disc;
		margin-left:40px;
	}
	ol li {
		list-style-type: decimal;
		margin-left:40px;
	}
	.centerLeft {
		width:25%;
		display: inline-block;
		padding: 0 10px;    
		margin-top: 123px;
		max-width:330px;
		min-width:250px;
		
	}
	.centerRight {
		width:calc(75% - 100px);
		display: inline-block;
		float: left;
		padding:0 20px 60px 150px;
		position: relative;
        //@include transform(translateY(-90px));
        margin-top: -100px;
        p, ul, ul li, ol, ol li {
            a {
                &:hover {
                    color: $LinkColor;
                }
            }
        }
		a {
			color:#000;
			text-decoration: underline;
			&:hover{
				color: #fff;
				text-decoration: none;
				span{
					color: #fff;
				}
			}
		}
		h1 {
			display: block;
			color:#000000;
			padding-bottom:10px;
			margin-bottom: 60px;
			@include text-shadow(3px 3px 3px rgba(#000000,0.4));
			&.pageTitle {
				text-align:center;
				color:#FFFFFF;
				@include transform(translateY(-100%));
				padding-bottom:100px;
				position: absolute;
				top:0;
				width:100%;
				text-transform:uppercase;
			}
		}
		h4 {
			text-align: center;
		    color: #132b43;
		    font-weight: 700;
		    margin-bottom: 30px;
		    margin-top: 60px;
		    padding-top: 0px;
		    display: block;
		    font-size: 40px;
		}
		h5 {
			font-weight:600;
			color:$LinkColor;
			text-align:center;
			font-size:24px;
			&.articleDate {
				text-transform:uppercase;
				font-size:12px;
				margin: 0 0 60px 0;
			}
		}
		ul {
			margin-bottom:20px;
			li {
				list-style-type: disc;
				margin-left:30px;
			}
		}
		p {
			text-align:justify;
			margin-top:0;
			font-weight:100;
			    line-height: 24px;
			&.turquoiseHeaderText {
				color:#4ebbb6;
				padding:30px;
				font-size:24px;
				font-weight:300;
			}
			&.contentQuoteText {
				font-weight:400;
				font-style: italic;
				color:#132b43;
			}
		}
		.titleQuicklinks {
			position: relative;
			//@include transform(translateY(-50%));
		}

		.boxLink {
			display: block;
			clear:both;
			background-color:$turquoise;
			padding:20px 60px 20px 20px;
			position: relative;
			color:#FFFFFF;
			margin: 20px 0;
		    max-width: 300px;
		    text-transform:uppercase;
		    @include transition($t1)
		    &:after {
		    	content:'';
		    	position: absolute;
		    	right:20px;
		    	top:50%;
		    	@include transform(translateY(-50%))
		    	width:20px;
		    	height:20px;
		    	background-image:url(../images/rightBracketWhite.svg);
		    	background-size:contain;
		    	background-position: center center;
		    	background-repeat: no-repeat;
		    }
		    &:hover {
		    	background-color:$LinkColor;
		    }
		}

		.socialLinksWrapper {
			display: block;
			text-align:center;
			position: relative;
			padding:60px 0;
			border-top:2px solid $LinkColor;
			.backToArchive {
				display: inline-block;
				background-color:$LinkColor;
				padding:12px 20px 10px 20px;
				color:#FFFFFF;
				text-transform:uppercase;
				font-weight:100;
			}
			.socialLinks {
				position: absolute;
				right:0;
				/*top:50%;
				@include transform(translateY(-50%))*/
				bottom:60px;
				text-align:right;
				.title {
					display: inline-block;
					margin:0;
					font-size:18px;
					color:$lighterGrey;
					text-transform: uppercase;
				}
				.socialLinkIcon {
					display: inline-block;
					margin:0  0 0px 20px;
					width:20px;
					height:20px;
					background-size:contain;
					background-position: center center;
					background-repeat: no-repeat;
					&.facebook, &.twitter {
						svg {
							path {
								fill:$lighterGrey;
								@include transition($t1)
							}
						}
						&:hover {
							svg {
								path {
									fill:$textGrey;
								}
							}
						}
					}
				}
			}
		}

		table {
			width:100%;
			border-collapse: collapse;
			thead {
				background-color:$turquoise;
				color:$darkBlue;
				
			}
			tbody {
				tr:nth-child(even) {
					background-color:$veryLightGrey;
				}
			}
			tr {
				th, td {
					padding:20px;
					color:$textGrey;
				}
			}
		}
		.contentObject{
			
			margin-bottom:30px;
				
			.leftSideImage, .rightSideImage {
				display: flex;
				
				.ContentObjectImage {
					width:300px;
					min-width:300px;
					max-width:300px;
					background-size:cover;
					background-position:center center;
					
					&.ImageLeft {
						margin-right:60px;
					}
					&.ImageRight {
						margin-left:60px;
					}
				}
				.leftSideImageContent {
					&.langShow {
						display: block;
					}
				}
			}
			p {
				text-align:justify;
				margin-top:0;
				&.turquoiseHeaderText {
					color:#4ebbb6;
					padding:30px;
					font-size:24px;
					font-weight:300;
					line-height: 30px;
				}
			}
			.contentTabWrapper {
				display: flex;
				margin-top:60px;
				border-bottom:1px solid rgba($textGrey,0.4);
				box-sizing:border-box;
				.contentTabObject {
					display: inline-block;
					padding:0 0 20px 0;
					margin:0 60px 0 0;
					
					text-transform: uppercase;
					cursor:pointer;
					.contentTabTitle {
						color:rgba($textGrey,0.4);
						font-weight:600;
						@include transition($t1);
						font-size:14px;
					}
					&.active {
						border-bottom:5px solid $LinkColor;
						.contentTabTitle {
							color:$LinkColor;
						}
					}
					&:not(.active):hover {
						.contentTabTitle {
							color:rgba($textGrey,0.6);
						}
					}
				}
			}
			.contentBoxWrapper {
				display:block;
/*				border-bottom:1px solid rgba($textGrey,0.4); */
				padding:60px 0;
				.contentBoxObject {
					display:none;
					&.active {
						display:block;
					}
					.contentBoxContent {
						p {
							margin-top: 10px;
							margin-bottom:10px;
						}
					}
				}
			}

		}

		.userform{
				.field {
					margin:30px 0;
					label {
						color:$LinkColor;
						/*font-family: $font-museo;*/
						font-weight: 100;
					}
					.text, .textarea {
						min-width:250px;
						width:100%;
						padding:10px;
						/*border: 1px solid $darkBlue;*/
						margin-top:10px;
						clear:both;
					}
					
				}
				.checkboxset {
					legend {
						color: $LinkColor;
						font-weight: 100;
					}
					.middleColumn {
						.checkboxset {
							border:1px solid $lightGrey;
							list-style-type: none;
							li {
								
								margin-left:0px;
								list-style-type: none;
								padding:0 !important;
								&:before, &:after {
									display:none;
								}
								.checkbox {
									display:none;
									&:checked {
										&+ label {
											background-color:$LinkColor;
											color:#FFFFFF;
										}
									}

								}
								label {
									padding:10px !important;
									display:block;
								}
							}
						}

					}
				}
				.optionset {
					legend {
						color: $LinkColor;
						font-weight: 100;
					}
					.middleColumn {
						.optionset {
							
							list-style-type: none;
						    max-height: 40vh;
    						overflow-y: scroll;
							li {
								padding:10px;
								margin-left:0px;
								list-style-type: none;
								&:before {
									display:none;
								}
								&:after {
									content:'';
									border:1px solid $LinkColor;
									position: absolute;
									left:10px;
									top:0%;
									border-radius: 0;
									width:20px;
									height:20px;
									@include transform(translateX(0%) translateY(50%) !important)
									background-color:transparent;
									pointer-events:none;
								}
								.radio {
									display:none;
									&:checked {
										+label:before {
											content:'';
											position: absolute;
											left:0px;
											top:10PX;
											border:1px solid $LinkColor;
											width:20px;
											height:20px;
											background-color:$LinkColor;
											color:#FFFFFF;
											pointer-events:none;
										}
									}

								}
								label {
									padding:10px 10px 10px 30px;
									position: relative;
									    width: calc(100% - 50px);
									
								}
								&:hover:after {
									background-color:rgba($LinkColor, 0.5);
								}
							}
						}

					}
				}
				.file {
					.middleColumn {
						.file {
							padding:10px;
							border-radius:0;
							border:1px solid $lightGrey;
							color:$LinkColor;
						}
					}
				}
				.action {
					min-width:250px;
					width:100%;
					padding:10px;
					border: 1px solid $LinkColor;
					border-radius:0;
					background-color:transparent;
					margin-bottom:30px;
					/*color:$darkBlue;*/
					@include transition($t1);
					&:hover {
						background-color:$LinkColor;
						color:#FFFFFF;
					}
				}
			}
			form {
				fieldset {
					border:none;
					padding:0;
					.text, .textarea {
						margin-bottom:20px;
						label {
							margin-bottom:10px;
							color:$LinkColor;
						}
						.middleColumn {
							input, textarea {
								padding:10px 20px;
								width:100%;
								max-width:300px;
								font-size:18px;
								margin-top:10px;
								color:$LinkColor;
								
								border:2px solid $LinkColor;
							}
						}
					}
					.middleColumn {
						.error{
						   display: inline-block;
						   float: left;
						   clear: both;
						   padding: 10px 20px;
						   margin-bottom: 20px;
						   border: 1px solid #c70000;
						   color: #c70000;
						   background-color: #ffe7e7;
						}
					}
				}
				.Actions {
					.action {
						padding:10px 20px;
						margin-top:20px;
						width:100%;
						max-width:344px;
						border:2px solid $LinkColor;
						color:$LinkColor;
						text-align:center;
						@include transition($t1);
						background-color:transparent;
						font-size:18px;
						text-transform: uppercase;
						&:hover {
							background-color:$LinkColor;
							color:#FFFFFF;
						}
					}
				}
			}

		#SearchResults li {
			margin-bottom:30px;
		}
		
	
		.contentWrapper {
			margin-bottom:60px;
		}
		
		.articleImage {
			width:100%;
			height:400px;
			background-size:cover;
			background-position:center center;
			margin-bottom:40px;
		}

		.linkedGalleryWrapper {
			.galleryItem {
				width:100%;
				max-width:520px;
				background-size: cover;
				background-position: center center;
				margin:60px auto;
				/*height:10vw;*/
				position: relative;
				.galleryItemInner {
					padding:9vw 60px;
					background-color:rgba(0,0,0,0.6);
					overflow:hidden;
					.galleryInnerTop {
						color:#FFFFFF;
						font-weight:600;
						text-transform: uppercase;
						margin-bottom:20px;
					}
					.galleryInnerBottom {
						color:#FFFFFF;
						font-weight:100;
						text-transform: uppercase;
					}
					.viewGallery {
						position: absolute;
					top:calc(50% - 60px);
					left:100px;
					@include transform(translateY(-50%))
					z-index:2;
					color:$LinkColor;
					font-size:16px;
					opacity:0;
					@include transition($t1);
					text-transform:uppercase;
					}
					
					&:before {
						content:'';
						position: absolute;
						left:50%;
						top:50%;
						@include transform(translateX(-50%) translateY(-50%))
						width:100%;
						height:100%;
						border:2px solid transparent;
						z-index:2;
						@include transition($t1)
						pointer-events:none;
					}
					&:hover {
						&:before {
							width:calc(100% - 44px);
							height:calc(100% - 44px);
							border:2px solid $LinkColor;
						}
						
							.viewGallery {
								
								top:calc(50% - 60px);
								opacity: 1;left: 60px;
							}
						
					}
				}
				
			}
		
		}
		
		
		

	}
	
}

body.GalleryPage {
    #quickLinks.noticesVisible {
        padding-bottom: 0 !important;
    }
}

body.page_results {
    .centerColumn {
        .centerRight {
            //@include transform(translateY(0));
            margin-top: 0;
        }
    } 
}