.scrollToTop {
	position: relative;
	z-index: 4000;
	cursor: pointer;
	svg {
		line, polygon {
			@include transition($t1)
		}
	}
	&:hover {
		svg {
			line, polygon {
				stroke:$LinkColor;
			}
		}
	}
}

/*
|===============================================================
|	Scroll Top
|===============================================================
*/

.scrollToBottom {
	position: absolute;
	bottom: 30px;
	left: 50%;
	width: 50px;
	height: 50px;
	margin-left: -30px;
	background: grey;
	padding: 10px;
	border-radius: 50%;
	z-index: 4000;
	cursor: pointer;
	i {
		color: white;
	}
}