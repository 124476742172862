.resultsWidget {
    position: relative;
    background-size: cover;
    background-position: center center;
    padding: 190px 30px 160px 30px;
    background-attachment: fixed;
    width: calc(100% - 60px);
    z-index: 2;
    .titleWrapper {
        max-width: 1280px;
        margin: 0 auto;
        position: relative;
        z-index: 2;
        .resultsWidgetTitle {
            margin: 0 0 90px 0;
            display: block;
            .resultsWidgetTitleTop {
                color: #ffffff;
                text-transform: uppercase;
                font-size: 30px;
                text-align: center;
                font-weight: 600;
            }
            .resultsWidgetTitleBottom {
                color: rgba(225, 227, 228, 0.3);
                text-transform: uppercase;
                font-size: 50px;
                text-align: center;
                font-weight: 600;
            }
        }
    }
    .resultsWrapper {
        text-align: center;
        z-index: 2;
        position: relative;
        .resultsItem {
            display: inline-block;
            margin: 0 20px;
            text-align: center;
            max-width: 200px;
            vertical-align: top;
            .resultsNumber {
                color: #ffffff;
                text-align: center;
                font-size: 60px;
                font-weight: 600;
                min-width: 187px;
                text-transform: uppercase;
                border-bottom: 2px solid #ffffff;
                padding-bottom: 10px;
                margin-bottom: 20px;
                span.points {
                    color: #ffffff;
                }
            }
            .resultsTitle {
                color: #ffffff;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                text-transform: uppercase;
                vertical-align: top;
            }
            .resultsText {
                color: #ffffff;
                margin: 4px 0;
            }
        }
    }
    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($darkBlue, 0.9);
        z-index: 1;
    }
}

.ofstedWidget {
    position: relative;
    background-size: cover;
    background-position: center center;
    padding: 190px 30px 160px 30px;
    background-attachment: fixed;
    width: calc(100% - 60px);
    z-index: 2;
    .titleWrapper {
        max-width: 1280px;
        margin: 0 auto;
        position: relative;
        z-index: 2;
        .ofstedWidgetTitle {
            margin: 0 0 90px 0;
            display: block;
            .ofstedWidgetTitleTop {
                color: $darkBlue;
                text-transform: uppercase;
                font-size: 30px;
                text-align: center;
                font-weight: 600;
                span {
                    font-weight: bold;
                    text-decoration: underline;
                }
            }
            .ofstedWidgetTitleBottom {
                color: $lightGrey;
                text-transform: uppercase;
                font-size: 50px;
                text-align: center;
                font-weight: 600;
            }
        }
    }
    .ofstedWidgetTitleTop {
        span {
            font-weight: bold;
            text-decoration: underline;
        }
    }
    .ofstedWrapper {
        text-align: center;
        z-index: 2;
        position: relative;
        .ofstedItem {
            display: inline-block;
            margin: 0 20px;
            text-align: center;
            max-width: 200px;
            vertical-align: top;
            margin-bottom: 20px;
            .ofstedImage {
                min-height: 150px;
                min-width: 150px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                margin-bottom: 20px;
                a {
                    display: block;
                    height: 100%;
                    width: 100%;
                    min-height: 150px;
                }
            }
            .ofstedTitle {
                color: $darkBlue;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                text-transform: uppercase;
                vertical-align: top;
            }
        }
        .textWrapper {
            max-width: 1000px;
            margin: 0 auto;
            margin-top: 20px;
        }
        .reportLinkWrapper {
            text-align: center;
            padding-top: 25px;
            .reportBtn {
                display: inline-block;
                padding: 20px 60px;
                background-color: $darkBlue;
                color: #ffffff;
                text-transform: uppercase;
                margin: 20px auto;
                @include transition($t1);
                &:hover {
                    background-color: $LinkColor;
                }
            }
        }
    }
    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 1;
    }
}

.clubsWidget {
    position: relative;
    background-size: cover;
    background-position: center center;
    padding: 190px 30px 160px 30px;
    background-attachment: fixed;
    width: calc(100% - 60px);
    z-index: 2;
    .titleWrapper {
        max-width: 1280px;
        margin: 0 auto;
        position: relative;
        z-index: 2;
        .clubsWidgetTitle {
            margin: 0 0 90px 0;
            display: block;
            .clubsWidgetTitleTop {
                color: $darkBlue;
                text-transform: uppercase;
                font-size: 30px;
                text-align: center;
                font-weight: 600;
            }
            .clubsWidgetTitleMiddle {
                color: #ffffff;
                text-transform: uppercase;
                font-size: 50px;
                text-align: center;
                font-weight: 600;
            }
            .clubsWidgetTitleBottom {
                color: #ffffff;
                max-width: 1000px;
                margin: 0 auto;
                text-align: center;
                span {
                    font-weight: bold;
                    text-decoration: underline;
                }
            }
        }
    }
    .clubsWrapper {
        text-align: center;
        z-index: 2;
        position: relative;
        .clubsItem {
            display: inline-block;
            margin: 0 20px;
            text-align: center;
            max-width: 200px;
            vertical-align: top;
            margin-bottom: 20px;
            .clubsTitle {
                color: $darkBlue;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                text-transform: uppercase;
                vertical-align: top;
            }
            .clubsImage {
                min-height: 150px;
                min-width: 150px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                margin-bottom: 20px;
                a {
                    display: block;
                    height: 100%;
                    width: 100%;
                    min-height: 150px;
                }
            }
        }
        .clubsLinkWrapper {
            text-align: center;
            padding-top: 40px;
            .clubsBtn {
                display: inline-block;
                padding: 20px 60px;
                background-color: $darkBlue;
                color: #ffffff;
                text-transform: uppercase;
                margin: 20px auto;
                @include transition($t1);
                &:hover {
                    background-color: $LinkColor;
                }
            }
        }
    }
    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $turquoise;
        z-index: 1;
    }
}
