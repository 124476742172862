/*** Mobile Screens ***/
#sidebar{
  width: 100%;
      max-width: 250px;
  margin-bottom: 40px;
  float: right;
  position: relative;

  .Level-1{
    display: none;
    ul{
      display: none;
      clear: both;
      float: left;
      width: 100%;
    }
  }

  button{
    position: absolute;
    top: 0px;
    border: none;
    outline: none !important;
    right: 0px;
    cursor: pointer;
    width: 30px;
    height: 28px;
    @include transition($t1);
    background-color: transparent;
    font-size: 1.3em;
    color: $textDark;
    &:hover{
      color: $themeColor;
    }
  }
  .sidebar-heading{
    display: block;
    /*float: left;*/
    clear: both;
    /*width: 80%;*/
    background-color:$LinkColor;
    padding:22px 20px 20px 20px;
    margin-bottom:5px;
    position: relative;
    a{

      display: block;
      text-decoration: none;
      color: #FFFFFF;
      text-transform: uppercase;
    }
    /*
    &:after {
      content:'';
      position: absolute;
      right:20px;
      top:50%;
      @include transform(translateY(-50%))
      width:20px;
      height:20px;
      background-image:url(../images/downBracketWhite.svg);
      background-size:contain;
      background-position: center center;
      background-repeat: no-repeat;
    }*/
  }
  ul{
    padding:0;
    margin:0;

    ul{
      display: none;

          a{background-color: rgba(26, 27, 27, 0.12) !important;}
      li{
        a{
          /*** Level Indentation ***/
          
        }
      }

      ul{
        li{
          a{
            /*** Level Indentation ***/
           
          }
        }
      }
    } 
    li{
      clear: both;
      float: left;
      width: 100%;
      position: relative;
      margin:0;
      
      list-style-type: none;
      
      @include transition($t1);
      a{
        display: block;
        text-transform:uppercase;
        text-decoration: none;
        padding:22px 20px 20px 20px;
        margin:0 0 5px 0;
        background-color:$veryLightGrey;
        &.current, &.currentChild {
          background-color: $lightGrey;
        }
        span{
          color: $darkBlue;
          @include transition($t1);
        }
        
        
      }
      &:hover{
        &>a {
          background-color: $lightGrey;
        }
        
      }
    }
  }
}

#sidebar-inner{
  overflow: hidden;
  float: left;
  clear: both;
  width: 100%;
}

/*** Desktop Screens ***/
@media all and (min-width: 768px) {
  #sidebar{
    .Level-1{
      display: block !important;
      margin-top: 0% !important;
    }
    ul{
      ul{
        display: none;
      }
    }
    .mainToggle:first-of-type{
      display: none  !important;
    }
  }
  .hideSideBarToggle{
    display: none;
  }
}



.sidebar-heading i.fa.fa-angle-down {
    color: #fff;
    font-size: 2.3em;
    position: absolute;
    top: 50%;
    cursor: pointer;
    @include transform(translateY(-50%) rotate(0deg));
    right: 16px;
    @include transition($t1);
}

.sidebar-heading.active i.fa.fa-angle-down {
    @include transform(translateY(-50%) rotate(-180deg));
}

.currentLevel{
  display: block ;
}
