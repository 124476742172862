#breadcrumbs {
	background-color: $mainColor;
	padding: 10px 0;
	padding-left: 60px;
	p {
		color: white;
		margin: 0;
		span {
			color: white;
		}
	}
}
