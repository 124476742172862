#quickLinks {
	display: block;
	position: relative;
	z-index: 2000;
	overflow: hidden;
	width:100%;
	text-align:center;
	font-size: 0.8em;
	@include order(1)
	a {
		width:auto;
		position: relative;
		background-color:$veryLightGrey;
		.titleText {
			
			@include transition($t1)
			display:inline-block;
			padding:20px 30px;
			position:relative;
			text-align:center;
			white-space: nowrap;
			margin-left:-4px;
			z-index:2;
			text-transform: uppercase;
			/*&:before {
				content:'';
				position: absolute;
				left:0;
				top:50%;
				@include transform(translateY(-50%))
				width:1px;
				height:20px;
				background-color:$LinkColor;
			}*/
			/*&:after {
				content:'';
				position: absolute;
				right:0;
				top:50%;
				@include transform(translateY(-50%))
				width:2px;
				height:20px;
				background-color:$LinkColor;
			}*/
			
			
		}
		&:after {
			content:'';
			position: absolute;
			right:-2px;
			top:50%;
			@include transform(translateY(-50%))
			width:2px;
			height:20px;
			background-color:$LinkColor;
			z-index:1;
		}
		/*&:last-child:after {
			display:none;
		}*/
		&:hover {
			&:after {
				@include animation(quicklinkHoverAnim 0.65s forwards);
			}
			.titleText {
				color:#FFFFFF;
			}
		}
	}
	&.noticesVisible {
		display:flex;
		//padding-bottom:100px;
		background-color:#FFFFFF;
		a {
			width:100%;
			.titleText {
				padding:24px 0px 20px 0px;
				width:100%;
				margin-left:0;
			}
		}
	}
	
}


.quicklinksWrapper {
	background-color:$veryLightGrey;
	/*@include transform(translateY(-50%))*/
	width:100%;
	#quickLinks {
		display:flex;
		a {
			width:100%;
		}
	}
}

body.HistoryPage, body.ContactForm, body.GalleryPage {
	.quicklinksWrapper {
		max-width: 1280px;
    	margin: 0 auto;
    	@include transform(translateX(-50%) translateY(-50%))
    	position: absolute;
    	left:50%;
    	z-index:3;
	}
}


@keyframes quicklinkHoverAnim{
	0% {
		width:2px;
		height:20px;
	}
	20% {
		width:2px;
		height:100%;
	}
	100% {
		width:calc(100% + 2px);
		height:100%;
	}
}
