.galleryFrame {
	width:100vw;
	.content {
		width:100%;
		.galleryImage {
			width:calc(25vw - 4px);
			height:20vw;
			border:2px solid #FFFFFF;
			display: inline-block;
			float:left;
			background-size: cover;
			background-position:center center;
			position: relative;
			a {
				width:100%;
				height:100%;
				position:absolute;
				left:0;
				top:0;
				background-color: rgba(0,0,0,0.3);
				@include transition($t1)
				.view {
					position: absolute;
					left:50%;
					top:50%;
					@include transform(translateX(-50%) translateY(-50%) scale(0,0))
					    padding: 7px 15px;
    font-size: 0.8em !important;
					background-color:#FFFFFF;
					color:$LinkColor;
					font-weight:100;
					text-transform: uppercase;
					@include transition($t1)

				}
				&:hover {
					background-color: rgba(0,0,0,0);
					.view {
						@include transform(translateX(-50%) translateY(-50%) scale(1,1))
					}
				}
			}
			&.galleryImage-9 {
				height:calc(40vw + 4px);
				
			}
			&.galleryImage-10 {
				width:calc(50vw - 4px);
				height:calc(40vw + 4px);
			}

		}
	}
}


/*
|===============================================================
|	Gallery Template #1
|	Uses a simple square gallery list w/ Fanybox using
| 	background-image in front end to display image.
|	@responsive - stacked on smaller screens 
|===============================================================
*/

.galleryTemplate-1 {
	.galleryImage {
		position: relative;
		display:inline-block;
		text-align:center; 
		.info {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			.click, .bg {
				@include transition(all 0.5s);
			}
			.click {
				color: white;
				opacity: 0;
				z-index: 10;
			}
			.bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: $mainColor;
				opacity: 0;
				z-index: 5;
			}
		}
		&:hover {
			.info {
				.bg {
					opacity: 0.8;
				}
				.click {
					opacity: 1;
				}
			}
		}
	}
}

/*
|===============================================================
|	Gallery Template #2
|	Uses a thumbnail controlled BX Slider instance.
| 	A large image preview is used to display images.
| 	Small thumbnails are used to change large image preview.
|	@responsive - via JS
|===============================================================
*/

.galleryTemplate-2 {

	#bx_slider {
		height: 500px;
		li {
			height: 100%;
			text-align: center;
			margin-left:0;
			img {
				display:inline-block;
				height: 100%;
			}
		}
	}

	.bx_pages_frame {
		position: relative;
		#bx_pages {
			margin-top: 20px;
			li {
				background-position: center center;
				cursor: pointer;
				height: 200px;
			}
		}
		.controls {
			i {
				position: absolute;
				top: 50%;
				color: $mainColor2;
				font-size: 24px;
				margin-top: -12px;
				cursor: pointer;
				@include transition(all 0.5s);
				&:hover {
					opacity: 0.5;
				}
			}
			i.prev {
				left: 0;
			}
			i.next {
				right: 0;
			}
		}
	}

}

/*
|===============================================================
|	Gallery Template #3
|	Masonry layout gallery version (Requires .grid)
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }

.galleryBottom {
	padding:60px;
	display: block;
	clear:both;
	text-align:center;
	.fullStory {
		padding: 12px 20px 10px 20px;
		display:inline-block;
		color:#FFFFFF;
		text-transform:uppercase;
		font-size:15px;
		margin:0 20px 20px 0;
		background-color:$LinkColor;
		@include transition($t1)
		font-weight:100;
		&:hover {
			background-color:$darkBlue;
		}
	}
}
