.instagramWidget {
	display:flex;
	
	.instagramItem {
		position: relative;
		width:100%;
		height:20vw;
		/*background-size:cover;*/
		background-size: 100% 100%;
		background-position:center center;
		position: relative;
		overflow:hidden;
		.titleOverlay {
			position: absolute;
			left:0;
			top:0;
			width:100%;
			height:100%;
			background-color:rgba($textGrey,0.6);
			color:#FFFFFF;
			@include transition($t1);
			@include transform-origin(0 0)
			.titleOverlayInner {
				position: absolute;
				left: 50%;
				top: 41%;
				width: calc(100% - 80px);

				@include transform(translateX(-50%));
				.date{
					font-weight: bold;
					padding-bottom: 10px;
					text-transform: uppercase;
				}
				.content {
					color:#FFFFFF;
					text-transform:uppercase;
					font-weight:100;
					line-height:30px;
				}
			}
		}
		.linkOverlay {
			position: absolute;
			left:0;
			top:0;
			@include transform(translateY(100%) scale(1,0));
			background-color:rgba($turquoise,0.9);
			@include transition($t1)
			width:100%;
			height:100%;
			@include transform-origin(0 100%)
			.linkOverlayInner {
				position: absolute;
				left:50%;
				top:50%;
				@include transform(translateX(-50%) translateY(-50%))
				.postLink, .postAdd {
					padding: 16px 20px 10px 18px;
					border:1px solid #FFFFFF;
					color:#FFFFFF;
					text-transform:uppercase;
					font-size:15px;
					margin: 10px 0px 10px 0;
					@include transition($t1)
					text-align:center;
					font-weight:100;
					&:hover {
						background-color:#FFFFFF;
						color:$darkBlue;
					}
				}
			}
		}
		&:hover {
			.titleOverlay {
				@include transform(scale(1,0))
			}
			.linkOverlay {
				@include transform(translateY(0%) scale(1,1))
			}
		}
	}
}