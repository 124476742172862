.historyWrapper {
	display:block;
	text-align:left;
	clear:both;
	z-index:1;
	background-color:$veryLightGrey;
	width:100vw;
	overflow:auto;
	padding-bottom:98px;
	/*.historyRow {
		display: flex;*/
		.historyObject {
			width:calc(20vw - 0px);
			height:30vw;
			
			position: relative;
			padding-top:15vw;
			display:inline-block;
			vertical-align: top;
			border:2px solid $veryLightGrey;
			float:left;
			
			box-sizing:border-box;
			overflow:hidden;
			.historyObjectImage {
				    position: absolute;
    width: 100%;
    height: 43vh;
    background-size: cover;
    background-position: center;
    left: 0px;
    top: 0px;
				@include transform(translateX(0%) translateY(0%))
				z-index:1;
				@include transition($t1)
				@include transition-timing-function(ease-in-out)
			}
			.historyObjectInfo {
				    background-color: #FFFFFF;
				    padding: 20px;
				    /* height: calc(15vw - 40px); */
				    text-align: center;
				    /* float: left; */
				        min-height: 27vh;
				    /* position: relative; */
				    z-index: 2;
				    position: absolute;
				    bottom: 0px;
				    left: 0px;

					        box-sizing: border-box;
					        -o-box-sizing: border-box;
					        -ms-box-sizing: border-box;
					        -moz-box-sizing: border-box;
					        -webkit-box-sizing: border-box;

   					 width: 100%;
				z-index:2;
				.historyObjectInfoInner {
					/*position: absolute;
					top:37%;
					    font-size: 0.8em;
					@include transform($center)
					    width: 100%;
					    left: 50%;*/
					        box-sizing: border-box;
   					 width: 100%;
					.year {
						font-size: 30px;
						color:$darkBlue;
						padding:0 0 10px 0;
						border-bottom:1px solid $LinkColor;
						margin: 0 auto 20px auto;
    					max-width: 80%;
						font-weight:700;
					}
					.title {
						color:$textGrey;
						font-weight:100;
						font-size: 0.8em;
					}
					.socialLinks {
						position: relative;
					    overflow: hidden;
					    text-align: center;
					    float: left;
					    clear: both;
					    height: 20px;
					    width: 100%;
					    margin-top: 27px;
						.title {
							display: inline-block;
							margin:0;    
							font-size: 14px;
							color:$lighterGrey;
							text-transform: uppercase;
							@include transition($t1)
							position:absolute;
							top:0px;
							left:50%;
							@include transform(translateX(-50%))
							width:100%;
						}
						.socialIconsWrapper {
							position:absolute;
							top:60px;
							left:50%;
							@include transform(translateX(-50%))
							.socialLinkIcon {
								display: inline-block;
								margin:0  10px 0px 10px;
								width: 15px;
    							height: 15px;
								background-size:contain;
								background-position: center center;
								background-repeat: no-repeat;
								
								@include transition($t1)
								
								&.facebook, &.twitter {
									svg {
										path {
											fill:$lighterGrey;
											@include transition($t1)
										}
									}
									&:hover {
										svg {
											path {
												fill:$textGrey;
											}
										}
									}
								}
							}
						}
						
						&:hover {
							.title {
								@include transform(translateX(-50%) translateY(-60px))
							}
							.socialIconsWrapper{
								top:0;
							}
						}
					}
				}
				
			}
			
			&.keyDate {
				width:40vw;
				padding-top:0;
				height:30vw;

				.historyObjectImage {
					height: 100% !important;
				}
				.historyObjectInfo {
					width:50%;
					height:100%;
					margin-left:50%;
					background-color:rgba(#FFFFFF,0.7);


					.historyObjectInfoInner{
					position: absolute;
					top: 50%;
					width: calc(100% - 40px);
					left: 50%;
					@include transform($center);
					}


				}
			}
			&:hover {
				.historyObjectImage {
					@include transform(translateX(0%) translateY(0%) scale(1.2,1.2))
				}
			}
		}
	/*}*/
	
}

.timelineWrapper {
	/*position: absolute;*/
	left:0;
	bottom:0;
	width:calc(100% - 40px);
	z-index:2;
	padding:40px 20px;
	background-color:#FFFFFF;
	text-align:center;
	
	.timelineInner {
		display: inline-block;
		margin:0 auto;
		position: relative;
		&:after {
			content:'';
			position: absolute;
			top:7px;
			left:15px;
			width:calc(100% - 30px);
			height:1px;
			border-top:1px dotted $midGrey;
			z-index:1;
		}
		div[data-name="timelineItem"] {
			display: inline-block;
			z-index:2;
			position: relative;
			.timelineDot {
				width:12px;
				height:12px;
				border-radius:50%;
				background-color:$LinkColor;
				margin:0 15px;
				opacity:0;
				@include transition($t1)

			}
			.timelineYear {
				position: absolute;
				top:-30px;
				left:50%;
				@include transform(translateX(-50%))
				opacity:0;
				@include transition($t1)
				background-color:$LinkColor;
				color:#FFFFFF;
				font-size:14px;
				padding:2px 5px;
				&:after {
					content:'';
					position: absolute;
					bottom:0;
					left:50%;
					width:2px;
					height:20px;
					background-color:$LinkColor;
					@include transform(translateX(-50%) translateY(100%))
				}
			}
			&.active {
				&.number1 {
					.timelineDot {
						opacity:0.2;
					}
					
				}
				&.number2 {
					.timelineDot {
						opacity:0.5;
					}
					
				}
				&.number3 {
					.timelineDot {
						opacity:1;
					}
					.timelineYear {
						opacity:1;
					}
					
				}
				&.number4 {
					.timelineDot {
						opacity:0.5;
					}
					
				}
				&.number5 {
					.timelineDot {
						opacity:0.2;
					}
					
				}
			}
			&:hover {
				.timelineDot, .timelineYear{
					opacity:1 !important;
				} 
				
							
			}
			&:first-child, &:last-child {
				.timelineDot{
					opacity:1 !important;
				} 
				.timelineYear {
					opacity:1 !important;
					color:$darkBlue !important;
					background-color:transparent !important;
					font-size:17px !important;
					&:after {
						display:none !important;
					}
				}	
			}
		}
	}
}