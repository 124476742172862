body.ContactForm {
	.contactFormWrapper {
		display:flex;
		.contactFormLeft {
			width:calc(60% - 120px);
			padding:60px;
			background-color:$veryLightGrey;
			.regionsTitle{
				font-size:40px;
				color:$darkBlue;
				font-weight:800;
				margin-bottom:60px;
			}
			.Region {
				float:left;
				margin:0 60px 30px 0;
				border-left:2px solid $turquoise;
				padding-left:20px;
				float:left;
				width:calc(50% - 82px);
				min-width:300px;
				h5.regionTitle {
					font-size:20px;
					margin:0 0 10px 0px;
					.regionTitleIcon {
						width:20px;
						height:20px;
						margin-right:20px;
						background-image:url(../images/locationIconRed.svg);
						background-size:contain;
						background-position: center top;
						background-repeat: no-repeat;
						display:inline-block;
					}
				}
				.schoolWrapper {
					.schoolItem {
						cursor: pointer;
						margin-bottom:30px;
						.schoolItemTitle {
							font-weight:600;
							text-transform: uppercase;

						}
					}
				}
			}
		}
		.contactFormRight {
			width:calc(40% - 120px);
			padding:60px;
			.contactFormTitle{
				font-size:40px;
				color:$darkBlue;
				font-weight:800;
				margin-bottom:60px;
			}
			.userform{
				.field {
					/*margin:30px 0;*/
					margin-bottom:5px;
					label {
						color:$LinkColor;
						/*font-family: $font-museo;*/
						font-weight: 100;
					}
					.text, .textarea {
						/*min-width:250px;*/
						width:100%;
						/*padding:10px;*/
						/*border: 1px solid $darkBlue;*/
						/*margin-top:10px;*/
						clear:both;
						label {
							display:none;

						}
						.middleColumn {
							input.text, textarea.textarea {
								border:none;
								background-color:$veryLightGrey;
								width:calc(100% - 40px);
								padding:10px 20px;
								color:$lighterGrey;
								font-size: 14px;
							    font-weight: 100;
							}
						}
					}
					.dropdown {
						label{
							display:none;
						}
						.middleColumn {
							select.dropdown {
								padding:10px 20px;
								color:#FFFFFF;
								background-color:$veryDarkGrey;
								border:0px solid transparent;
								border-radius: 0px;
							    width: 100%;
							    position: relative;

							    font-size: 14px;
							    text-transform: uppercase;
							    font-weight: 100;
							    @include appearance(none);
							    background-image:url(../images/formDownArrow.svg);
							    background-position: calc(100% - 40px) 50%;
							    background-repeat: no-repeat;
							    &.selectSchool {
							    	background-color:$turquoise;
							    }
							    &:after {
							    	content:'';
							    	position: absolute;
							    	top:50%;
							    	right:40px;
							    	width:30px;
							    	height:30px;
							    	background-image:url(../images/formDownArrow.svg);
							    	background-size:contain;
							    	background-position: center center;
							    	
							    }
							}
						}
					}
					
				}
				.checkboxset {
					legend {
						color: $LinkColor;
						font-weight: 100;
					}
					.middleColumn {
						.checkboxset {
							border:1px solid $lightGrey;
							list-style-type: none;
							li {
								
								margin-left:0px;
								list-style-type: none;
								padding:0 !important;
								&:before, &:after {
									display:none;
								}
								.checkbox {
									display:none;
									&:checked {
										&+ label {
											background-color:$LinkColor;
											color:#FFFFFF;
										}
									}

								}
								label {
									padding:10px !important;
									display:block;
								}
							}
						}

					}
				}
				.optionset {
					legend {
						color: $LinkColor;
						font-weight: 100;
					}
					.middleColumn {
						.optionset {
							
							list-style-type: none;
						    max-height: 40vh;
    						overflow-y: scroll;
							li {
								padding:10px;
								margin-left:0px;
								list-style-type: none;
								&:before {
									display:none;
								}
								&:after {
									content:'';
									border:1px solid $LinkColor;
									position: absolute;
									left:10px;
									top:0%;
									border-radius: 0;
									width:20px;
									height:20px;
									@include transform(translateX(0%) translateY(50%) !important)
									background-color:transparent;
									pointer-events:none;
								}
								.radio {
									display:none;
									&:checked {
										+label:before {
											content:'';
											position: absolute;
											left:0px;
											top:10PX;
											border:1px solid $LinkColor;
											width:20px;
											height:20px;
											background-color:$LinkColor;
											color:#FFFFFF;
											pointer-events:none;
										}
									}

								}
								label {
									padding:10px 10px 10px 30px;
									position: relative;
									    width: calc(100% - 50px);
									
								}
								&:hover:after {
									background-color:rgba($LinkColor, 0.5);
								}
							}
						}

					}
				}
				.file {
					.middleColumn {
						.file {
							padding:10px;
							border-radius:0;
							border:1px solid $lightGrey;
							color:$LinkColor;
						}
					}
				}
				.action {
					min-width:250px;
					width:100%;
					padding:10px;
					border: 1px solid $LinkColor;
					border-radius:0;
					background-color:transparent;
					margin-bottom:30px;
					/*color:$darkBlue;*/
					@include transition($t1);
					&:hover {
						background-color:$LinkColor;
						color:#FFFFFF;
					}
				}
			}
			form {
				fieldset {
					border:none;
					padding:0;
					margin:0;
					padding:0;
					
				}
				.Actions {
					.action {
						padding: 20px;
						margin-top:20px;
						width:100%;
						
						border:2px solid $veryLightGrey;
						color:$turquoise;
						text-align:center;
						@include transition($t1);
						background-color:#FFFFFF;
						font-size:18px;
						text-transform: uppercase;
						&:hover {
							background-color:$LinkColor;
							color:#FFFFFF;
						}
					}
				}
			}
		}
	}
}