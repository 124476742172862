* {
    font-family: $font-stack;
    color: $textGrey;
    text-decoration: none;
    outline: none !important;
    letter-spacing: 1px;
    line-height: 1.4em;
}

a {
    cursor: pointer;
}

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #fff;
    margin: 0;
    padding: 0;
}

*.langShow {
    display: inherit;
}

*.langHide {
    display: none !important;
}
.contentBoxContent {
    * img,
    img {
        max-width: 100%;
        height: auto !important;
        max-width: 100% !important;
    }
}
table {
    max-width: 100% !important;
}

/*
|===============================================================
|	Column Position
|===============================================================
*/
.col-1 {
    min-width: 8.33%;
    width: 8.33%;
}
.col-2 {
    min-width: 16.66%;
    width: 16.66%;
}
.col-3,
.col-1-4 {
    min-width: 25%;
    width: 25%;
}
.col-4,
.col-1-3 {
    min-width: 33.33%;
    width: 33.33%;
}
.col-5 {
    min-width: 41.66%;
    width: 41.66%;
}
.col-6,
.col-1-2 {
    min-width: 50%;
    width: 50%;
    position: relative;
}
.col-7 {
    min-width: 58.33%;
    width: 58.33%;
}
.col-8 {
    min-width: 66.66%;
    width: 66.66%;
}
.col-9 {
    min-width: 75%;
    width: 75%;
}
.col-10 {
    min-width: 83.33%;
    width: 83.33%;
}
.col-11 {
    min-width: 91.66%;
    width: 91.66%;
}
.col-12,
.col-2-2,
.row {
    min-width: 100%;
    width: 100%;
}
.col-five,
.col-1-5 {
    width: 20%;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.row {
    position: relative;
    float: left;
}

.newsFilter {
    display: block;
    position: relative;
    width: 250px;
    /* float: right; */
    z-index: 9;
    min-height: 42px;
    margin: 0 auto 20px auto;
    float: none;
    * {
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
}

.Page .newsFilter {
    width: 120px !important;
}

.newsFilterBtn:after {
    content: "";
    position: absolute;
    right: 20px;
    top: 50%;
    @include transform(translateY(-50%) rotate(0deg));
    width: 20px;
    height: 20px;
    background-image: url(../images/downBracketWhite.svg);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.newsFilterBtn:hover {
    opacity: 0.9;
}

.pageLink .newsFilterBtn:after {
    background-image: none !important;
}

#homepageNewsSlider .content {
    font-size: 0.8em;
    font-weight: lighter;
    text-align: justify;
}

.newsFilterBtn {
    padding: 12px 20px 10px 20px;
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: inline-block;
    text-transform: uppercase;
    position: relative;
    background-color: #d92240;
    cursor: pointer;
    margin-bottom: 30px;
    * {
        color: #fff;
        display: inline-block;
        vertical-align: middle;
    }
    span {
        padding-right: 20px;
    }
}
.newsFilters {
    display: none;
    background-color: #343c44;
    position: absolute;
    bottom: 0px;
    left: 0px;
    @include transform(translateY(100%));
}
.ActiveFilters {
    .newsFilters {
        display: block;
    }
    .newsFilterBtn:after {
        @include transform(translateY(-50%) rotate(-180deg));
    }
}

.activeFilter {
    background-color: rgba(255, 255, 255, 0.4);
}
.CategoryToggle {
    cursor: pointer;
    color: #fff;
    padding: 12px 20px 10px 20px;
    @include transition($t1);
    &:hover {
        background-color: rgba(255, 255, 255, 0.4);
    }
}

.allEvents {
    @include transition($t1);
    margin-bottom: 50px;
    display: inline-block;
}

.allEvents:hover {
    background-color: #fff !important;
    color: #132b43 !important;
}

.overlayButton {
    @include transition($t1);
}
.overlayButton:hover {
    background-color: #fff !important;
    color: #d92240 !important;
}
.historyWrapper
    .historyObject.keyDate
    .historyObjectInfo
    .historyObjectInfoInner
    .socialLinks
    .title {
    color: #132b43 !important;
}

.historyWrapper
    .historyObject.keyDate
    .historyObjectInfo
    .historyObjectInfoInner
    .socialLinks
    .socialIconsWrapper
    .socialLinkIcon.facebook
    svg
    path,
.historyWrapper
    .historyObject.keyDate
    .historyObjectInfo
    .historyObjectInfoInner
    .socialLinks
    .socialIconsWrapper
    .socialLinkIcon.twitter
    svg
    path {
    fill: #132b43 !important;
}

.showcaseEventOverlayWrapper
    .showcaseEventOverlayItem
    .showcaseEventOverlayContent
    .Content {
    * {
        color: #fff;
    }
}

.SidebarSpan {
    color: #fff;
}

body.ContactForm
    .contactFormWrapper
    .contactFormRight
    .userform
    .field
    .text
    .middleColumn
    input.text,
body.ContactForm
    .contactFormWrapper
    .contactFormRight
    .userform
    .field
    .text
    .middleColumn
    textarea.textarea,
body.ContactForm
    .contactFormWrapper
    .contactFormRight
    .userform
    .field
    .textarea
    .middleColumn
    input.text,
body.ContactForm
    .contactFormWrapper
    .contactFormRight
    .userform
    .field
    .textarea
    .middleColumn
    textarea.textarea {
    color: #acafb2 !important;
}
body.ContactForm
    .contactFormWrapper
    .contactFormRight
    .userform
    .field
    .text
    .middleColumn
    input.text::placeholder,
body.ContactForm
    .contactFormWrapper
    .contactFormRight
    .userform
    .field
    .text
    .middleColumn
    textarea.textarea,
body.ContactForm
    .contactFormWrapper
    .contactFormRight
    .userform
    .field
    .textarea
    .middleColumn
    input.text::placeholder,
body.ContactForm
    .contactFormWrapper
    .contactFormRight
    .userform
    .field
    .textarea
    .middleColumn
    textarea.textarea::placeholder {
    color: #acafb2 !important;
}

.InstagramDate {
    color: #fff !important;
}

.CalendarFilter {
    font-size: 0.8em;
}

.AddAllDates {
    background-color: #f5f5f5;
    background-color: #f5f5f5;
    padding: 10px 50% 10px 20px;
    text-align: left !important;
    display: block;
    text-transform: uppercase;
    color: #343c44 !important;
    font-size: 0.6em;
    line-height: 1.2em !important;
    position: relative;
    img {
        position: absolute;
        top: 50%;
        right: 15px;
        width: 20px;
        @include transform(translateY(-50%));
    }
}

img.left {
    display: inline-block;
    float: left;
    padding: 0px 20px 20px 0px;
}
img.right {
    display: inline-block;
    float: right;
    padding: 0px 0px 20px 20px;
}

.checkbox + label.left {
    font-size: 0.8em;
    color: #2b2b2b !important;
}

body.ContactForm .contactFormWrapper .contactFormRight form .Actions .action {
    padding: 7px !important;
    cursor: pointer;
}
.ButtonVacancies,
a.ChildPageButton {
    display: inline-block;
    padding: 10px 20px;
    background-color: #f5f5f5;
    vertical-align: middle;
    margin: 3px;
    @include transition($t1);
    &:hover {
        background-color: #e1e3e4;
    }
}

.ChildPageButtons {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    display: none;
}

.primaire .ChildPageButtons {
    display: block !important;
}

.wideLink {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.Tac {
    text-align: center;
}
.ContactSection {
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    * {
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    margin-bottom: 30px;
    width: 100%;
    background-color: #f5f5f5;
    clear: both;
    width: 100%;
    max-width: 250px;
    float: right;
    .ContactSectionInner {
        width: 100%;
        padding: 10px 20px;
    }
}
.DepartmentContact {
    background-color: #132c43;
    padding: 20px 20px;
    clear: both;
    width: 100%;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
}
.DepartmentContactEmail {
    text-align: center;
    display: block;
    clear: both;
    width: 100%;
    background-color: #d7d8d9;
    padding: 10px 20px;
    text-transform: uppercase;
    color: #132c43;
}

.ContactSectionDetails {
    clear: both;
    width: 100%;
    padding: 5px 0px;
}
.SectionLabel {
    clear: both;
    width: 100%;
    font-weight: bold;
}
.SectionInfo {
    clear: both;
    width: 100%;
}

.contentTabWrapper {
    clear: both;
}
#homepage-events,
.resultsWidget,
.homepageNewsSliderWrapper,
.keyFacts,
.clubsWidget,
.ofstedWidget {
    clear: both;
    float: left;
    width: 100%;
}

h1 *,
h2 *,
h3 *,
h4 * {
    font-size: 1em !important;
}

.twitterSVG {
    height: 25px;
    margin-bottom: -10px;
    @include transform(translateY(-4px) translateX(-7px));
}

.questionTitleRight {
    i {
        color: #fff;
        font-size: 2em;
    }
}

@media all and (min-width: 800px) {
    .newsFilter {
        font-size: 0.7em !important;
        position: absolute !important;
        top: -78px !important;
        right: 10px !important;
    }
    .newsFilters {
        bottom: 5px !important;
    }
}

.clubsOverlayContent {
    * {
        color: #fff;
    }
}

.fancybox-prev span {
    background-position: 3px -37px;
}
.fancybox-next span {
    background-position: 3px -71px;
}

input#Form_NewsLetterForm_Sign_up_for_our_newsletter {
    /*height: 57px !important;*/
}

.dibImg {
    float: left;
}

.socialLinksWrapper .twitterSVG {
    height: 36px;
    margin-bottom: -13px;
}

.questionTitleRight {
    position: relative;
    overflow: hidden;
}

.questionTitleRight i {
    color: #fff;
    font-size: 2em;
    position: absolute;
    top: 50%;
    left: 50%;
    @include transition($t1);
    @include transform(translateX(-50%) translateY(-50%) rotate(0deg));
}

.questionTitle.active .questionTitleRight i {
    @include transform(translateX(-50%) translateY(-50%) rotate(-90deg));
}

.fancybox-title-float-wrap .child .titleWrapper .titleLeft {
    color: transparent !important;
}
