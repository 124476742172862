/*
|===============================================================
|	Uploads
|===============================================================
*/

.uploadWrapper {
	margin-bottom: 30px;
}

.upload {
	position: relative;
	display: block;
	width: calc(100% - 55px);
	
	clear: both;
	/*float: left;*/
	padding:10px 25px 10px 30px;
	border: 2px solid $lightGrey;
	margin: 20px 0 0 0;
	background-color: transparent;
	@include transition($t1);
	border-radius:33px;
	&:before {
		content:'';
		position: absolute;
		left:33px;
		top:23px;
		width:0px;
		height:0px;
		border-radius:33px;
		background-color:$LinkColor;
		@include transform( translateY(-50%))
		z-index:1;
		pointer-events:none;
	}
	&:after {
		content:'';
		position: absolute;
		right:33px;
		top:50%;
		@include transform(translateX(-50%) translateY(-50%))
	}
	.uploadTitle {
		text-transform: uppercase;
		font-size: 1em;
	    display: inline-block;
	    padding-top: 5px;
	    position: relative;
	    z-index:2;
	    @include transition($t1);
	    color:$darkBlue;
	    font-weight:600;
	}
	.uploadArrow {
		width:20px;
		height:20px;
		position: absolute;
		right:20px;
		top:49%;
		@include transform(translateY(-50%) rotate(0deg));
		z-index:2;
		@include transition($t1);
		svg {
			path {
				fill:$darkBlue;
				@include transition($t1);
			}
		}
		
	}
	a {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.fileIcon {
	    background-color:$themeColor;
	    display: inline-block;
	    float: right;
	    width: 25px;
	    height: 30px;
	    @include transition($t1);
	    span {
	    	@include transition($t1);
	    	color: #fff;
		    background-color: #102d40;
		    height: 13px;
		    width: 100%;
		    padding-top: 2px;
		    font-size: 7px;
		    text-transform: uppercase;
		    display: block;
		    text-align: center;
		    margin-top: 8px;
	    }
	}

	&:hover {
		.uploadTitle {
			color:#FFFFFF;
		}

		.uploadArrow {
		@include transform(translateY(-50%) rotate(90deg));
			svg {
				path {
					fill:#e1e3e4;
				}
			}
		}
		&:before {
			@include animation(uploadHoverAnim 1s forwards)
		}
	}

	* {
		vertical-align: middle;
		
	}
}

@keyframes uploadHoverAnim {
	0% {
		width:0px;
		height:0px;
	}
	20% {
		width:66px;
		height:calc(100% + 4px);
		left:-2px;
	}
	100% {
		width:calc(100% + 4px);
		height:calc(100% + 4px);
		left:-2px;
	}
}
