/*** Mobile Navigation ***/
#MenuIcon {
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 4000;
    top: 0px;
    right: 0px;
    cursor: pointer;
    overflow: hidden;
    color: white;
    font-size: 28px;
    display:none;
    
    border-radius:50%;
    @include transition($t1);
    &:before {
      position:absolute;
      @include transform(translateX(-50%) translateY(-50%))
      left:50%;
      top:50%;
    }
    span {
      position: absolute;
      display: block;
      height: 5px;
      width: 45%;
      left: 50%;
      background-color: $textLight;
      @include transform(translateX(-50%) rotate(0deg));
      @include transition($t1);
    }
    &:hover {
      color: $LinkColor;
    }
    p {
      font-size: 0.8em;
      color: $textLight;
      text-transform: uppercase;
      font-weight: lighter;
      position: absolute;
      left: 50%;
      @include transform(translate(-50%));
      bottom: 12px;
    }
    span:nth-child(1) {
      top:22px;
    }
    span:nth-child(2) {
      top: 33px;
    }
    span:nth-child(3) {
      top: 44px;
    }
    span:nth-child(4) {
      top: 55px;
    }
}

.mobileNavBg {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  z-index: 6005;
  visibility: hidden;
  @include transition(all 1s);
  opacity: 0;
}

#MenuBg {
  position: absolute;
  width: 0%;
  height: 300%;
  left: 50%;
  top: 50%;
  background-color: $themeColor;
  @include transform(translate(-50%, -50%) rotate(-45deg));
  @include transition(all 1s ease-in-out);
  display: none !important;
}
#MobileNavigation{
  
  *{
    list-style: none !important;
  }

  background-color: $themeColor;
  @include transition(all 1s);
  position: fixed;
  z-index: 5999;
  top: 0px;
  right: -60%;
  height: 100%;
  overflow: hidden;
  width: 60%;
  min-width: 300px;
  opacity: 0;
  .closeMenu {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 40px;
    height: auto;
  }
  .toggleNav {
    cursor: pointer;
    background-color: transparent;
    position: absolute;
    right: 0px;
    font-weight: lighter;
    top: 5px;
    width: 30px;
    height: 30px;
    color: #eee;
    font-size: 27px;
    border: none;
    outline: none;
  }
  .TopLevel {
    padding: 10px;
    position: absolute;
    display: inline-block;
    left: 50%;
    top: 50%;
    overflow-y: scroll;
    @include transform($center);
    min-width: 300px;
    text-align: left;
    max-height:60vh;
    overflow-x:hidden;
    &:before {
      content: '';
      position: absolute;
      display: block;
      border-left: 1px solid #fff;
      border-top: 1px solid #fff;
      opacity: 0.6;
      height: 50px;
      width: 50px;
      top: -20px;
      left: -20px;
    }
  }
  ul {
    ul {
      display: none;
      /*background: rgba(255,255,255,0.05);*/
      list-style-type: none;
      padding-left:10px;
    }
    li {
      position: relative;
      a {
        color: $textLight;
        text-align: left;
        font-size: 1em;
       
        font-family: $font-stack;
        width: calc(100% - 54px);
        display: block;
        font-weight: lighter;
        padding: 10px 34px 10px 20px;
        letter-spacing: 2px;
        text-decoration: none !important;
        @include transition($t1);
        &:hover {
          background: rgba(255,255,255,0.1);
        }
      }
    }
  }
}
.activeMobileMenu {
  overflow: hidden;
  #MobileNavigation {
    display: block;
    right: 0px;
    opacity: 1  !important;
    margin-top: 0px !important ;
    height: 100%;
  }
  .mobileNavBg {
    visibility: visible;
    opacity: 1;
  }
  #MenuBg {
    width: 200%;
    height: 300%;
  }
   #MenuIcon {
      span:nth-child(1) {
        top: 35px;
        @include transform(translateX(-50%)  rotate(45deg));
      }
      span:nth-child(3) {
        @include transform(translateX(-50%)  rotate(-45deg));
        top: 35px;
      }
      span:nth-child(2) {
        margin-left: 100px !important;
        opacity: 0 !important;
        width: 50%;
      }
      span:nth-child(4) {
        opacity: 0 !important;
      }
  }
}
