.searchBox {
	width:40px;
	height:40px;
	position:fixed;
	background-color: $LinkColor;
	top:0px;
	right:30px;
	background-image:url(../images/search.svg);
	background-size:50% 50%;
	background-position:center 10px;
	background-repeat: no-repeat;
	z-index:5799;
}
.searchHolder{
	width:40vw;
	max-width:488px;
	position: absolute;
	top:0;
	right:0;
	display:flex;
	@include transform(translateY(-100px))
	@include transition($t3)
				@include transition-timing-function(cubic-bezier(1, 0.01, 0.06, 1))

	.searchClose {
		width:15px;
		height:15px;
		background-image:url(../images/searchClose.svg);
		background-size:contain;
		background-position: center center;
		background-repeat: no-repeat;
	}
	&.show {
		@include transform(translateY(0%))
	}
}

#SearchForm_SearchForm {
	position:relative;
	display:none;
	top:0;
	right:0;
	width:100%;
	max-width:450px;
	z-index:9999;
	
	fieldset {
		border:none;
		padding:0;
		margin:0;
		#SearchForm_SearchForm_Search {
			position: relative; 
			/*left:0;
			width: 300px;*/
			font-weight:100;
			padding: 0px;
			border:1px solid transparent;
			background-color:transparent;
			color:#FFFFFF;
			/*font-style: italic;*/
			border: 0px;
			/*border-bottom:4px solid #FFFFFF;*/
			z-index: 8888;
			/*font-size:80px;*/
			font-size:16px;
			text-transform:uppercase;
			/*border-bottom: 1px dotted #FFFFFF;*/
		}
		#SearchForm_SearchForm_action_results{
		    position: absolute;
		    color: #ddd;
		    @include transition($t1);
			@include appearance(none);
		    outline: 0 !important;
		    z-index: 9980;
		    font-family: WebSymbols;
		    text-decoration: none !important;
		    display: inline-block;
		    text-align: center;
		    cursor: pointer;
		    border: none !important;
		    margin-left: 20px;
		    font-size: 2em;
		    background-color: transparent;
		    right:0;
		    width:80px;
		    height:80px;
		    background-image:url(../images/searchIconWhite.svg);
		    background-repeat: no-repeat;
		    background-position: center center;
		    background-size:contain;
		    margin-top:30px;
		    display:none;
		    &:hover{
		    	color: #fff;
		    }
		    i {
		    	color:#FFFFFF;
		    }
		}
	}
}
