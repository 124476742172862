.homepageEventsSliderWrapper, #homepage-events {
	position: relative;
	/*float: left;*/
	background-size:cover;
	background-position:center 40%;
	padding: 90px 30px 90px 30px;
	background-attachment: fixed;
	width: calc(100% - 60px);
	background-color:$turquoise;
	.titleWrapper {
		max-width:1280px;
		margin:0 auto;
		position: relative;
		.eventsWidgetTitle {
			margin:0 0 90px 0;
			display:block;
			.eventsWidgetTitleTop {
				color:$darkBlue;
				text-transform:uppercase;
				font-size:30px;
				text-align:center;
				font-weight:600;
			}
			.eventsWidgetTitleBottom {
				color: rgba(255,255,255,0.3);
				text-transform:uppercase;
				font-size:50px;
				text-align:center;
				font-weight:600;
			}
		}
	}

	
	.widgetsWrapper {
		width:calc(100% - 240px);
		max-width:1280px;
		margin:0 auto;
		position: relative;
		z-index: 2000;
		.sliderControls {
			padding: 83px 0 0 0;
			position: relative;
			text-align:center;
			#eventsNext, #eventsPrev {
				position: absolute;
				top:-100px;
				background-size:contain;
				background-position: center center;
				background-repeat: no-repeat;
				width:100px;
				height:25px;
				a {
					position: absolute;
					left:0;
					top:0;
					width:100%;
					height:100%;
				}
			}
			#eventsPrev {
				left:-20px;
				@include transform(translateX(-100%))
				background-image:url(../images/eventsPrevArrow.svg);
			}
			#eventsNext {
				right:-20px;
				@include transform(translateX(100%))
				background-image:url(../images/eventsNextArrow.svg);
			}
			.allEvents {
				color:#FFFFFF;
				text-transform: uppercase;
				background-color:$darkBlue;
				padding:20px 40px;
				margin:0 auto;
			}
		}

	}
	#sliderUpcoming {
		width:100%;
		/*max-width:1280px;*/
		margin:0 auto;
		section {
			padding-bottom:10px;
			.sliderContent {
				background-color:$lightGrey;
				/*padding:15px;*/
				font-family: $font-stack;
				display:flex;

				.dateHolder {
					padding:20px;
					text-align:center;
					background-color:#FFFFFF;
					box-sizing: border-box;
					-o-box-sizing: border-box;
					-ms-box-sizing: border-box;
					-moz-box-sizing: border-box;
					-webkit-box-sizing: border-box;
					/*
					width:110px;
					min-width:110px;
					max-width:110px;
					*/

					    width: 174px;
    min-width: 110px;
    max-width: 174px;
    height: 203px;
					.top{
						color:$turquoise;
						font-size:80px;
						font-weight:800;
					}
					.bottom{
						color:$darkBlue;
						text-transform: uppercase;
						font-weight:600;
						position: relative;
						bottom: 12px
					}
				}
				.contentHolder {
					width:calc(100% - 150px);
					.title {
						padding:20px;
						height:91px;
						color:$textGrey;

					}
					.category {
						padding: 13px 20px 7px 20px;
						border-top:1px solid $darkBlue;
						color:$textGrey;
						text-transform: uppercase;
						font-weight:100;
						font-size: 13px;
						.categoryIcon {
							width:20px;
							height:20px;
							display: inline-block;
							margin:0 20px 0 0;
							background-image:url(../images/categoryIcon.svg);
							background-size:contain;
							background-position: center center;
							background-repeat: no-repeat;
							vertical-align:top;
						}
					}
				}
				
				.date {
					display: inline-block;
					font-weight: 700;
					font-size: 14px;
				}
				
			}
		}
		
		#eventsInner {
			z-index:1;
			section {
				margin-bottom:60px;
				.eventsContent {
					text-align: justify;
				}
			}
		}
	}

}