.showcaseEventsDropdownWrapper {
	position: absolute;
	top:calc(60% + 80px);
	width:100vw;
	text-align: center;
	z-index:4;
	.showcaseEventsDropdown {
		width:100%;
		max-width:450px;
		background-color:$textGrey;
		display:inline-block;
		.dropdownHeader {
			background-color:$LinkColor;
			padding:20px;
			color:#FFFFFF;
			text-transform:uppercase;
			text-align:left;
			display:block;
			position:relative;
			font-weight:100;
			font-size:14px;
			&:after{
				content:'';
				position: absolute;
				right:20px;
				top:50%;
				@include transform(translateY(-50%))
				width:20px;
				height:20px;
				background-image:url(../images/downBracketWhite.svg);
				background-size:contain;
				background-position: center center;
				background-repeat: no-repeat;
				@include transition($t1)
			}
		}
		.dropdown {
			display:none;
			.dropdownItem {
				display:flex;
				@include transition($t1)
				cursor:pointer;
				.title, .date {
					
					
					text-align:left;
					color:#FFFFFF;
					text-transform:uppercase;
					font-size:14px;
					font-weight:100;
				}
				.title {padding:20px 0 20px 20px; width:100%;}
				.date {padding:20px; white-space:nowrap;}
				&:hover {
					background-color:rgba(255,255,255,0.4);
				}
			}
		}
		&.active {
			.dropdownHeader {
				&:after {
					@include transform(translateY(-50%) rotate(180deg))
				}
			}
		}
	}
}

.showcaseEventWrapper {
	display: block;
	position: relative;
	clear: both;
	text-align:left;
	width:100vw;
	border:1px solid #FFFFFF;
	box-sizing:border-box;
	.showcaseEventItem {
		display: inline-block;
		float:left;
		border:2px solid #FFFFFF;
		width:calc(25vw - 4px);
		height:20vw;
		position: relative;
		box-sizing:border-box;
		overflow:hidden;
		background-color:$textDark;
		cursor:pointer;
		&.item-1 {
			width:calc(50vw - 8px);
			height:40vw;
		}
		&.item-2, &.item-3{
			width:calc(25vw - 2px);
			height:40vw;
		}
		&.item-6 {
			width:calc(50vw - 4px);
		}
		.showcaseEventImage {
			background-size: cover;
			background-position:center center;
			position:absolute;
			left:50%;
			top:50%;
			width:100%;
			height:100%;
			@include transform(translateX(-50%) translateY(-50%))
			@include transition($t1)
			background-color:rgba($textDark,0.3);
			z-index:1;
			opacity:0.5;
		}
		.viewMore {
			position: absolute;
			left:50%;
			top:50%;
			@include transform(translateX(-50%) translateY(-50%) scale(0,0))
			color:$LinkColor;
			text-transform: uppercase;
			z-index:2;
			@include transition($t1);
			padding: 7px 15px;
			font-size: 0.8em !important;
			background-color:#FFFFFF;
		}
		.showcaseEventTitle {
			position: absolute;
			left:50%;
			text-align: center;
			top:50%;
			@include transform(translateX(-50%) translateY(-50%) scale(1,1))
			color:#FFFFFF;
			text-transform: uppercase;
			z-index:2;
			@include transition($t1);
			padding-bottom: 10px;
			display: inline-block;
			border-bottom: 1px solid #e61e29;
		}

		.showcaseEventDate{
			position: absolute;
			color: #fff;
			bottom: -35px;
			left: 50%;
			font-size: 0.8em;
			text-align: center;
			font-weight: bold;
			width: 100%;
			@include transform(translateX(-50%));
		}

		&:hover {
			.viewMore {
				@include transform(translateX(-50%) translateY(-50%) scale(1,1))
			}
			.showcaseEventTitle {
				@include transform(translateX(-50%) translateY(-50%) scale(0,0))
			}
			.showcaseEventImage {
				@include transform(translateX(-50%) translateY(-50%) scale(1.2,1.2))
			}
		}
	}
}

.showcaseEventOverlayWrapper {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background-color:rgba(0,0,0,0.6);
	z-index:5;
	pointer-events:none;
	opacity:0;
	@include transition($t1)
	&.active {
		opacity:1;
		pointer-events:all;
	}
	.showcaseEventOverlayItem {
		position: absolute;
		left:50%;
		    max-width: calc(100% - 98px);
		top:50%;
		@include transform(translateX(-50%) translateY(-50%))
		background-color:$LinkColor;
		
		height:calc(100% - 40px);
		max-height: 768px;
		display:flex;
		opacity:0;
		pointer-events:none;
		&.active {
			opacity:1;
			pointer-events:all;
		}
		.showcaseOverlayClose {
			position: absolute;
			top:20px;
			right:20px;
			width:20px;
			height:20px;
			background-image:url(../images/overlayClose.svg);
			background-size:contain;
			background-position: center center;
			background-repeat: no-repeat;
		}
		.showcaseEventOverlayImage {
			width:100vw;
			background-size:cover;
			background-position:center center;
			max-width:640px;
		}
		.showcaseEventOverlayContent {
			width:100vw;
			max-width:520px;
			padding:60px;
			color:#FFFFFF;
			.Title {
				color:#FFFFFF;
				text-transform: uppercase;
				margin-bottom:40px;
				font-weight:100;
				display: block;
				font-size:22px;
			}
			.Content {
				height:250px;
				color:#FFFFFF;
				margin-bottom:40px;
				font-weight:100;
				display:inline-block;
				line-height:24px;
				text-align: justify;
			}
			.School, .Event {
				color:#FFFFFF;
				margin-bottom:10px;
				font-weight:100;
				font-size:12px;
				display:block;
				strong {
					color:#FFFFFF;
					text-transform:uppercase;
				}
			}
			.Location {
				color:#FFFFFF;
				font-weight:100;
				margin:30px 0 10px 0;
				text-transform:uppercase;
				font-size:12px;
				.locationIcon {
					display: inline-block;
					margin:0 10px 0 0;
					width:12px;
					height:12px;
				}
			}
			.startTime {
				color:#FFFFFF;
				font-weight:100;
				margin:0 0 10px 0;
				text-transform:uppercase;
				font-size:12px;
				.timeIcon {
					display: inline-block;
					margin:0 10px 0 0;
					width:12px;
					height:12px;
				}
			}
			
				.overlayButtons {
					margin-top:40px;
					a {
						display: inline-block;
						.overlayButton {
							display: inline-block;
							padding:10px 30px;
							border:1px solid #FFFFFF;
							margin:0 5px 20px 0;
							color:#FFFFFF;
							text-transform: uppercase;
							font-weight:100;
							font-size:12px;
						}
					}
				}
			
		}
	}
}

.showcasePrev{
    position: absolute;
    z-index: 99;
    color: #fff;
    font-size: 4em !important;
    top: 50%;
    left: -40px;
    cursor: pointer;
    *{
    color: #fff;
    }
    transform: translateY(-50%);
}
.showcaseNext{
    position: absolute;
    z-index: 99;
    color: #fff;
    font-size: 4em !important;
    top: 50%;
    right: -40px;
    cursor: pointer;
    *{
    color: #fff;
    }
    transform: translateY(-50%);
}

.showcaseEventOverlayWrapperClickout{
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	cursor: pointer;
}

.showcaseOverlayClose{
	cursor: pointer;
}