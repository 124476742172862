.translate {
	cursor: pointer;
}

#google_translate_element {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 9999;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%); 
}