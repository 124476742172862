.centerColumn {
	.centerRight {

		.galleryWrapper {
			display: block;
			
			position: relative;
			
			overflow:hidden;
			width:100%;
			a {
				&:nth-child(odd) {
					.galleryItem {
						margin:0 20px 20px 0;	
					}
									}
				&:nth-child(even) {
					.galleryItem {
						margin:0 0 20px 20px ;	
					}
					
				}
				.galleryItem {
					width:calc(50% - 20px);
				
					background-size: cover;
					background-position: center center;
					
					height:400px;
					position: relative;
					display: inline-block;
	    			float: left;
	    			overflow:hidden;
					.galleryItemInner {
						padding:60px;
						background-color:rgba(0,0,0,0.3);
						display: flex;
						flex-direction: column;
						justify-content: center;
						height: 100%;
						box-sizing: border-box;
						.viewGallery {
						/*	position: absolute;
							top:-40px;
							left:60px;
							font-weight:100;
							color:$LinkColor;
							text-transform:uppercase;
							font-size:14px;
							@include transition($t1)*/
							position: absolute;
							top:calc(50% - 60px);
							left:100px;
							@include transform(translateY(-50%))
							z-index:2;
							color:$LinkColor;
							font-size:16px;
							opacity:0;
							@include transition($t1);
							text-transform:uppercase;
						}
						.galleryInnerTop {
							color:#FFFFFF;
							font-weight:600;
							text-transform: uppercase;
							margin-bottom:20px;
						}
						.galleryInnerBottom {
							color:#FFFFFF;
							font-weight:100;
							text-transform: uppercase;
						}
					}
					&:after {
						content:'';
						position: absolute;
						left:50%;
						top:50%;
						@include transform(translateX(-50%) translateY(-50%))
						width:100%;
						height:100%;
						border:2px solid transparent;
						@include transition($t1)

					}
					&:hover{
						.galleryItemInner {
							.viewGallery {
								top:calc(50% - 60px);
								opacity: 1;left: 60px;
							}
						}
						&:after {
							width:calc(100% - 44px);
							height:calc(100% - 44px);
							border:2px solid $LinkColor;
						}
					}
				}
			}
			
			
		}

		.galleryImage {
			display: inline-block;
			border:2px solid $lightGrey;
			padding:20px;
			position: relative;
			margin:0 20px 30px 0;
			overflow:hidden;
			.thumb {
				width:200px;
				height:200px;
				border-radius:25px;
				background-size: cover;
				background-position:center center;
			}
			.galleryImageTitle {
				text-align: center;
				margin:20px 0 0 0;
				border-top:1px solid $LinkColor;
				padding-top:20px;
				@include transition($t1);
			}
			&:after {
				content:'';
				width:100%;
				height:100%;
				background-color: $LinkColor;
				position: absolute;
				left:0;
				bottom:0;
				z-index:-1;
				opacity:0;
				@include transition($t1);
			}
			&:hover {
				.galleryImageTitle {
					border-top:1px solid #000000;
				}
				&:after {
					opacity:1;
				}
			}
		}

	}

}