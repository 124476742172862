.homepageFooter {
  width: calc(100% - 120px);
  padding: 40px 60px;
  background-color: $textGrey;
  clear: both;
  .footerTop {
    display: flex;
    margin-bottom: 20px;
    .footerTopLeft {
      width: 100%;
      background-size: contain;
      background-position: left center;
      background-repeat: no-repeat;
      height: 155px;
      flex: 1 1 40%;
    }
    .footerTopRight {
      padding: 20px 0 20px 20px;
      text-align: right;
      width: 100%;
      .footerLogosWrapper {
        .homepageFooterLinksItem {
          margin: 0 0 0 15px; //75px;
          /*max-width: 130px;*/
          max-width: 120px; //247px;
          vertical-align: middle;
        }
      }
    }
  }
  .footerMiddle {
    display: flex;
    .footerMiddleLeft {
      width: 100%;
      .footerLinksWrapper {
        .homepageFooterLinksItem {
          padding: 13px 40px 11px 40px;
          min-width: 161px;
          text-align: center;
          box-sizing: border-box;
          -o-box-sizing: border-box;
          -ms-box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          text-transform: uppercase;
          display: inline-block;
          margin: 0 5px 20px 0;
          @include transition($t1) .title {
            color: #ffffff;
          }
          &:hover {
            background-color: #ffffff !important;
            .title {
              color: $LinkColor;
            }
          }
          &.ffffff {
            &:hover {
              background-color: $LinkColor !important;
              .title {
                color: #ffffff !important;
              }
            }
          }
        }
      }
    }
    .footerMiddleCenter {
    }
    .footerMiddleRight {
      text-align: right;
      color: #ffffff;
      width: 100%;
      .addressText {
        padding-right: 30px;
        border-right: 2px solid $LinkColor;
      }
      .phoneNumber {
        padding-left: 30px;
      }
      p {
        margin-top: 0;
        &.designedBy {
          font-size: 12px;
          color: $lightGrey;
          margin-bottom: 20px;
          a {
            color: $lightGrey;
          }
        }
      }
      span,
      a,
      p {
        color: #ffffff;
        text-align: right;
        text-transform: uppercase;
        font-weight: 300;
      }
    }
  }
  .footerCenter {
    .footerLinksWrapper {
      width: 100%;
      ul {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        text-align: center;
        li {
          display: inline-block;
          .homepageFooterLinkIconImg {
            max-width: 150px;
            margin: 0 20px;
          }
        }
      }
    }
  }
  #logoIcons {
    width: 100%;
    text-align: center;
    margin-top: 30px;

    ul {
      li {
        display: inline-block;
        list-style-type: none !important;
        img {
          width: 120px;
        }
      }
    }
  }
}

.footerLinksWrapper {
  margin-top: 49px;
}
