.centerColumn {
	.centerRight {
		.vacancyItem {
		    background-color: #f5f5f5;
    width: calc(50% - 80px);
    padding: 30px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: inline-block;
    float: left;
    position: relative;
			&:nth-child(even) {
				margin-right:10px;
			}
			&:nth-child(odd) {
				margin-left:10px;
			}
			.vacancyItemTitle {
				color:$darkBlue;
				font-size:24px;
				height:60px;
				margin-bottom:30px;
			}
			.vacancyInfo {
				p {
					color:$darkBlue;
					margin-bottom:5px;
					font-size:12px;
					span.title {
						color:$turquoise;
						text-transform:uppercase;
					}
				}
			}


			.fullLink{
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
			}
			.vacancyOverlay {
				position: absolute;
				left:50%;
				top:50%;
				@include transform(translateX(-50%) translateY(-50%) scale(0,0))
				width:100%;
				height:100%;
				background-color: $darkBlue;
				@include transition($t1)
				opacity:0;
				.findOutMore {
					position: absolute;
					left:50%;
					top:50%;
					@include transform(translateX(-50%) translateY(-50%) )
					font-size:14px;
					font-weight:100;
					color:#FFFFFF;
					text-transform: uppercase;
					@include transition($t1)
					&:hover {
						color:$turquoise;
					}
				}
			}
			&:hover {
				.vacancyOverlay {
					@include transform(translateX(-50%) translateY(-50%) scale(1,1))
					opacity:1;
				}
			}
		}
		.vacancyInfoHolder {
			display:flex;
			width:100%;
			background-color: $turquoise;
			margin-bottom:20px;
			.vacancyInfoLeft {
				padding: 10px 20px 5px 20px;
				@include column-count(2);
				width:calc(100% - 280px);
				p {
					color:#FFFFFF;
					font-size:14px;
					white-space: nowrap;
					margin-top:5px;
					margin-bottom:5px;
					display: inline-block;
					width:100%;
					line-height:18px;
					span.title {
						color:$darkBlue;
						text-transform:uppercase;
					}
				}
			}
			.vacancyInfoRight {
				padding:20px 40px;
				background-color: $darkBlue;
				width:200px;
				position: relative;
				a {
					
					.visitWebsite {
						color:#FFFFFF;
						text-transform:uppercase;
						white-space: nowrap;
						position: absolute;
						left:50%;
						top:50%;
						@include transform(translateX(-50%) translateY(-50%))
						font-weight:100;
					}
				}
			}
		}
		.vacancyInner {
			background-color:$veryLightGrey;
			padding:60px 40px;
			h5 {
				text-align:left;
				font-weight:100;
				color:$darkBlue;
				margin-top:20px;
			}
			.upload {
    			padding: 10px 25px 10px 30px !important;
    		}
		    .upload:before {
		    	top: 23px;
		    }
		}
	}
}
