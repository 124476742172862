.featuredArticleWrapper {
	display:block;
	border-bottom:10px solid $LinkColor;
	.featuredArticleTitle {
		margin:60px;
		text-align:center;
		color:$darkBlue;
		font-weight:700;
		font-size: 60px;
	}
	.articleHolder {
		display: flex;
		.featuredNewsItem {
			width:100%;
			position: relative;
			height:20vw;
			overflow:hidden;
			background-color:#000000;
			.articleThumb {
				background-size:cover;
				background-position: center center;
				position:absolute;
				left:50%;
				top:50%;
				@include transform(translateX(-50%) translateY(-50%))
				width:100%;
				height:100%;
				z-index:1;
				@include transition($t1)
				opacity:0.7;
			}
			.articleTitle{
				position: relative;
				z-index: 2;
				margin-top:5vw;
				color:#FFFFFF;
				text-transform: uppercase;
				text-align:left;
				font-weight:400;
				padding:0 40px;
			}
			.fullStory {
				position: relative;
				z-index:2;
				margin:9vw 40px 0 40px;
				padding:10px 20px;
				border: 1px solid $LinkColor;
				display:inline-block;
				color:#FFFFFF;
				text-transform: uppercase;
				font-weight:100;
				@include transition($t1)
				font-size:12px;
				position: absolute;
    			bottom: 40px;
				&:hover {
					background-color:$LinkColor;
				}
			}
			&:hover {
				.articleThumb {
					@include transform(translateX(-50%) translateY(-50%) scale(1.2,1.2))
				}
			}
		}
	}
}