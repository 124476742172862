.newsLetterWrapper {
	background-color:$veryLightGrey;
	padding:60px;
	text-align:center;
	clear:both;
	.title {
		color:$darkBlue;
		font-weight:700;
		font-size:30px;
		text-transform:uppercase;
		white-space:nowrap;
		display:inline-block;
	}
	.formWrapper {
		display: inline-block;
		/*width:100%;*/
		text-align:center;
		margin:0 60px;
		#Form_NewsLetterForm, #Form_NewsLetterFormFrench {
			
			display:flex;
			text-align:center;
			fieldset {
				padding:0;
				margin:0;
				border:none;
				display:inline-block;
				#Form_NewsLetterForm_Sign_up_for_our_newsletter_Holder, #Form_NewsLetterFormFrench_Inscrivez_Holder {
					label {
						display: none;
					}
					.middleColumn {
						input { 
							padding:22px 20px 18px 20px;
							border:none;
							background-color:#e7e8e9;
							color: #acafb2 !important;
							font-size:15px;
							text-transform:uppercase;
							width:300px;
							font-weight:100;
						}

						input::placeholder { 

							color: #acafb2 !important;
						}
					}
					
				}
			}
			.Actions {
				display:inline-block;
				position: relative;
				background-color:$LinkColor;
				&:after {
					content:'';
					position:absolute;
					right:0;
					top:0;
					width:0;
					height:100%;
					background-color: $darkBlue;
					@include transition($t1);
					//z-index:1;
				}
				&:hover {
					&:after {
						width:100%;
					}
				}
				input {
					padding:22px 40px 18px 40px;
					border:none;
					background-color: transparent;
					font-size:15px;
					text-transform:uppercase;
					border-radius:0;
					color:#FFFFFF;
					height:100%;
					display: inline-block;
					position: relative;
					z-index:1;
				}
			}
		}
	}
	a.allNewslettersLink {
		display:inline-block;
		padding:22px 40px;
	
		.allNewsletters {
			padding: 22px 40px 18px 40px;
			background-color:$darkBlue;
			
			text-transform: uppercase;
			position: relative;
			
			.allNewslettersText {
				position: relative;
				z-index:1;
				display:inline-block;
				color:#FFFFFF;
			}
			&:after {
				content:'';
				position:absolute;
				right:0;
				top:0;
				width:0;
				height:100%;
				background-color: $LinkColor;
				@include transition($t1);
				//z-index:1;
			}
			&:hover {
				&:after {
					width:100%;
				}
			}
			
		}
	}
}