#headerLinks {
	position:absolute;
	left: 60px;
    bottom: 200px;
    z-index:2;
    a {
    	.headerLinkThumb {
    		width:200px;
    		margin:0 20px 20px 0;
    	}
    }
}