
			.FAQtabWrapper {
				display:block;
				border-bottom:2px solid $lightGrey;
				margin-bottom:60px;
				.FAQtabTitle {
					display: inline-block;
					padding:20px 0;
					margin-right:60px;
					color:$midGrey;
					font-weight: bold;
					font-size: 14px;
					text-transform:uppercase;
					position: relative;
					cursor: pointer;
					&:after {
						content:'';
						position: absolute;
						left:50%;
						bottom:-2px;
						width:0px;
						height:5px;
						background-color:$LinkColor;
						@include transition($t1);
						@include transform(translateX(-50%))
					}

					&:hover, &.active{
						color:$LinkColor;
						&:after {
							width:100%;
						}
					}
				}
			}
			.FAQPage .newsFilter{
				width: 152px;
			}
			.FAQcontentWrapper {
				display: block;
				position: relative;
				.FAQcontentBox {
					/*position: absolute;
					top:0;
					width:100%;*/
					display:none;
					.questionItem {
						margin:0 0 20px 0;
						background-color:$answerBackground;
						display: block;
						.questionTitle {
							display:flex;

							.questionTitleLeft {
								background-color: $lighterGrey;
								padding:22px 20px 20px 20px;
								color:$darkBlue;
								width:100%;
								text-transform:uppercase;
								@include transition($t1)
								.number {
									color:$LinkColor;
									@include transition($t1)
								}
							}
							.questionTitleRight {
								background-color:$darkBlue;
								color:#FFFFFF;
								text-transform: uppercase;
								padding:20px 0px;
								width:0;
								@include transition($t1)
								cursor:pointer;
							}
							&:hover {
								.questionTitleLeft {
									background-color:$LinkColor;
									color:#FFFFFF;
									.number {
										color:#FFFFFF;
									}
								}
								.questionTitleRight {
									padding:22px 40px 20px 40px;
									width:inherit;
								}
							}
							&.active {
								.questionTitleLeft {
									background-color:$LinkColor;
									color:#FFFFFF;
									.number {
										color:#FFFFFF;
									}
								}
							}
						}
						.answerHolder {
							display:none;
							.questionContent {
								padding:40px 20px 20px 20px;
								font-weight:100;
								table{
									tbody{
										tr{
											&:nth-child(odd){
												background-color: #d3d3d3;
											}
											&:nth-child(even){
												background-color: #fafafa;
											}
										}
									}
								}
							}
						}
					}
					&:nth-child(1) {
						display: block;
					}
				}
			}
	