/*
|===============================================================
|	Calendar Page Template Widget
|===============================================================
*/

.centerColumn {
	.centerRight {
		.calendarWidgetFilter {
			margin-bottom:60px;
			.inner {
				display:flex;
				.calendarWidget {
					width:66%;
					padding:0 20px 20px 0;
				}
				.calendarCategories {
					width:34%;
					padding:0 0 0 20px;
					border-left:2px solid $turquoise;
					.CalendarFilter {
						.calendarFilterDropdown{
							margin-bottom:20px;
							.filterHeader {
								padding:12px 20px 10px 20px;
								background-color:$turquoise;
								color:#FFFFFF;
								text-transform:uppercase;
								cursor:pointer;
								font-weight:100;
								position: relative;
								&:after {
								  content:'';
								  position: absolute;
								  right:20px;
								  top:50%;
								  @include transform(translateY(-50%))
								  width:20px;
								  height:20px;
								  background-image:url(../images/downBracketWhite.svg);
								  background-size:contain;
								  background-position: center center;
								  background-repeat: no-repeat;
								  @include transition($t1)
								}
							}
							&.active {
								.filterHeader {
									&:after {
										@include transform(translateY(-50%) rotate(180deg))
									}
								}
								
							}
							.filterHolder {
								display:none;
								.schoolFilterItem {
									border-left:2px solid $lightGrey;
									border-bottom:2px solid $lightGrey;
									border-right:2px solid $lightGrey;
									padding:12px 20px 10px 20px;
									color:$darkBlue;
									text-transform:uppercase; 
									position: relative;
									cursor:pointer;
									&:after {
										content:'';
										position: absolute;
										right:20px;
										top:50%;
										@include transform(translateY(-50%) scale(0,0));
										width:20px;
										height:20px;
										background-image:url(../images/activeTick.svg);
										background-size:contain;
										background-position: center center;
										background-repeat:no-repeat;
										@include transition($t1)
									}
									&.selected {
										&:after {
											@include transform(translateY(-50%) scale(1,1));
										}
										
									}
								}
							}
						}
						.categoryFilterDropdown{
							margin-bottom:20px;
							.filterHeader {
								padding:12px 20px 10px 20px;
								background-color:$darkBlue;
								color:#FFFFFF;
								text-transform:uppercase;
								font-weight:100;
								position: relative;
								&:after {
								  content:'';
								  position: absolute;
								  right:20px;
								  top:50%;
								  @include transform(translateY(-50%))
								  width:20px;
								  height:20px;
								  background-image:url(../images/downBracketWhite.svg);
								  background-size:contain;
								  background-position: center center;
								  background-repeat: no-repeat;
								  @include transition($t1)
								}
							}
							&.active {
								.filterHeader {
									&:after {
										@include transform(translateY(-50%) rotate(180deg))
									}
								}
								
							}
							.filterHolder {
								display:none;
								.categoryFilterItem {
									border-left:2px solid $lightGrey;
									border-bottom:2px solid $lightGrey;
									border-right:2px solid $lightGrey;
									padding:12px 20px 10px 20px;
									color:$darkBlue;
									text-transform:uppercase;
									position: relative;
									.categoryColor{
										position: absolute;
										left:-2px;
										top:0;
										width:10px;
										height:calc(100% + 2px);
									}
									.activeTick {
										position: absolute;
										right:20px;
										top:50%;
										@include transform(translateY(-50%) scale(0,0));
										width:20px;
										height:20px;
										@include transition($t1)
									}
									&.selected {
										.activeTick {
											@include transform(translateY(-50%) scale(1,1));
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.feed{
			a{
				text-decoration: none;
			}
		}
		.eventsList {
			.eventsListItem {
				background-color: #ececec;
				display: block;
				position: relative;
				margin-bottom:20px;
				width:100%;
				display:flex;
				.categoryColor {
					position: absolute;
					left:-0px;
					top:0;
					width:10px;
					height:100%;
				}
				.eventLine1{
					text-transform: uppercase;
					font-weight:100;
					font-size:14px;
				}
				.eventInfo {
					padding:20px 30px;
					width:100%;
					.eventTitle {
						display: block;
						text-transform:uppercase;
						font-weight:100;
						font-size:14px;
					}
					.eventTitle, .eventLocation, .startTime, .ICSlink {
						display: inline-block;
						/*float:left;*/
						text-transform: uppercase;
						font-weight:100;
						/*margin:10px 20px 0 0;*/
						//white-space:nowrap;
						font-size:14px;
					}
				}
				a{
					text-decoration: none;
				}
				.viewEvent {
					
					white-space: nowrap;
					
					text-transform: uppercase;
					font-weight:100;
					height:calc(100% - 60px);
					color:#FFFFFF;
					
					padding:30px 30px;
					
				}
			}
		}
	}
}
table.calendar-widget-table {
	width: 100%;
	padding-bottom: 50px;
	margin:0 0 2em;
	thead {
		background-color:transparent !important;
		tr {
			th {
				border: none;
    			padding: 15px 20px;
				
				    font-size: 2.8em;
				.show-month, .next, .prev {
					display:inline-block;
					color: $textGrey;
					font-weight: 100;
					
    				font-size: 18px;
    				text-transform: uppercase;

					i {
						color: $textGrey;
					}
					.inner {
						color: #FFFFFF;
						width: auto;
					}
					.next, .prev {
						width:20px;
						height:20px;
					}
				}
				.show-month {
					margin: 0 30px;
				}
			}
		}
	}
	.calendar-header {
		td.calendar-header-day, td {
			background: transparent !important;
			color:$LinkColor;
			border:none !important;
		}
	}
	tbody{
		.calendar-day {
			border: 1px solid #ececec;
			color: #a9afb9;
			@include transition($t1);
		}
		tr {
			&:nth-child(even){ 
				background-color:transparent; 
			}
			td {
				padding: 20px 0;
				position: relative;
				text-align:center;
				.calEventPopup {
					position: absolute;
					top:40px;
					padding:20px;
					border:2px solid $LinkColor;
					background-color: $lightGrey;
					z-index: 2;
					    width: 180px;
					    text-align: center;
					    display:none;
					.dayTitle {
						color:$LinkColor;
						margin-bottom:20px;
						font-weight:500;
					}
					.eventLine {
						color:$LinkColor;
					}

				}
				&.hasEvent:hover {
					.calEventPopup {
						display:inline-block;
					}
				}
				&:nth-child(1) .calEventPopup { left:0; }
				&:nth-child(2) .calEventPopup { left:-20px; }
				&:nth-child(3) .calEventPopup { left:-40px; }
				&:nth-child(4) .calEventPopup { left:50%; @include transform(translateX(-50%)); }
				&:nth-child(5) .calEventPopup { right:-40px; }
				&:nth-child(6) .calEventPopup { right:-20px; }
				&:nth-child(7) .calEventPopup { right:0px; }
			}
		}
		.hasEvent {
		background-image: none;
			background-repeat: no-repeat;
			background-position: 5px 5px;
			background-size: 15px 15px;
		}
		.today {
			color: #676b71;
		}
		.hasEvent {
		background-image: none;
			background-repeat:no-repeat;
			background-position:10px 10px;
			background-size: 15px 15px;
		}
		.selected{color: #fff !important;}
		.out-of-month {
			background-color:transparent !important;
			opacity:0.2;
		}
	} 
	.calendar-day:hover, .selected {
		background-color: $LinkColor !important;
		color: #FFFFFF;
		font-weight: 500;
	}
}

.calendarWidget{
   /* padding: 10px;*/
}

/*
|===============================================================
|	Calendar Filter
|===============================================================
*/



/* .CalendarFilter{
    span{
        display: inline-block;
        padding: 5px 0px;
        margin: 0px 5px;
        cursor: pointer;
    }
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/*     display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
    display: block;
} */
.CalendarFilter span{
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    color: #333 !important;
    border-bottom: 2px solid transparent;
    margin-bottom: 10px;
}
.CalendarFilter span.active{
    border-bottom: 2px solid $themeColor !important;
    color: $themeColor !important;
}
.noEvents{
    max-width: 500px;
    display: none;
    padding: 20px 10px;
    color: #666 !important;
    @include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
    max-width: 500px;
    margin: 0px !important;
    padding: 0px !important;
}
#allEvents ul li,
#HomepageEvents ul li{
    @include border-radius(5px);
    margin-bottom: 10px;
    background-color: $LinkColor ;
    float: left;
    width: 100%;
    clear: both;
    margin-left: 0;
	span.suffix {
		font-size: 12px;
		vertical-align: top;
		margin-left: 2px;
		line-height: 6px;
	}
    @include transition(background 0.35s);
    display: block;
    &:hover{
        background-color: #2472c5 !important;
    }
    .toggleBtn{
        cursor: pointer;
        padding: 10px;
        padding-left: 20px;
        color: #fff;
        *{
			color: #fff;
        }
		.arrow {
			position: absolute;
			top: 15px;
			right: 15px;
			color: #fff;
			
			// Not sure why animation($t1) did not workkj
		    -webkit-transition-duration: 0.8s;
			-moz-transition-duration: 0.8s;
			-o-transition-duration: 0.8s;
			transition-duration: 0.8s;

			-webkit-transition-property: -webkit-transform;
			-moz-transition-property: -moz-transform;
			-o-transition-property: -o-transform;
			 transition-property: transform;
		}
		&.active {
			.arrow {
				@include transform(rotate(180deg));
			}
		}
    }
    .toggleContent{
		display: none;
		font-size: 0.8em;
		padding: 10px;
	        padding-left: 20px;
		*{
			font-size: 1em;
			color: #fff !important;
		}
		.url{
			padding: 10px;
	    	color: #333 !important;
	    	@include transition($t1);
	    	&:hover{

	    		text-decoration:underline;
	    	}
		}
	}
}


.toggleBtn{
	cursor: pointer;
}

