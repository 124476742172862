#usefulLinks {
	padding-left: 22px;
	padding-right: 19px;
	text-align:center;
	display:inline-block;
	vertical-align: top;
	a {
		
		.titleText {
			
			padding: 0px 6px;
			color:#FFFFFF;
			text-transform:uppercase;
			display:inline-block;
			font-weight:100;
			@include transition($t1)
			font-size:12px;
			&:hover {
				color:$hoverGrey;
			}
		}
	}
	
}

