header.innerPageHeader {
	background-color: $mainColor;
	height: 500px;
	background-size:cover;
}

body.ShowcaseEventsPage {
	header {
		h1.pageTitle {
			top:60%;
		}
	}
}

header {
	/*overflow:hidden;*/
	position:relative;
	height:calc(100vh + 100px);
	width:100%;
	.grad{
		position: absolute;
		z-index:1;
		left:0;
		top:0;
		width:100%;
		height:100%;
		pointer-events:none;
		opacity: 0.785;
		overflow:hidden;
		&:after {
			content: '';
			position: absolute;
			z-index:1;
			left:0;
			top:0;
			width:100%;
			height:100%;
			pointer-events:none;
			
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100;Neutral+Density */
			background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
		}
	}
	nav.main {
		position:absolute;
		top:0;
		z-index:7;
		width:100%;
		
		/*background-color:rgba(0,0,0,0.6);*/
		.headerTop {
			position: relative;
			background-color: $textGrey;
			width:calc(100% - 80px);
			text-align:right;
			padding:7px 40px 5px 40px;
			.languageSwitch {
				display: inline-block;
				margin:0 4px;
				color:#FFFFFF;
				@include transform(translateY(-2px));
				cursor: pointer;
				/*vertical-align: top;*/
				font-weight:100;
				text-transform: uppercase;
				@include transition($t1)
				font-size:12px;
				.flag {
						display:inline-block;
						width:30px;
						height:10px;
						margin-right: 5px;
						svg {
							path, rect {
								@include transition($t1)
								
							}
						}
					}
				&:not(.activeLang) {
					.flag {
						
					
						svg {
							
							#white {
								fill:#DDDDDD;
							}
							#blue {
								fill:#333333;
							}
							#red {
								fill:#666666;
							}
						}
					}
					
				}
				&.activeLang {
					font-weight:400;
					
				}
			}
			.socialIconWrapper {
				display:inline-block;
				    height: 12px;
    margin-top: 3px;
			}
			.socialIcon {
				/*width:10px;
				height:10px;*/
				
				width: 14px !important;
   	 			height: 14px !important;
				max-width: 14px !important;
   	 			max-height: 14px !important;

				display: inline-block;
				cursor:pointer;
				background-position: center center;
					background-size: 100% 100%;
					background-repeat: no-repeat;
					margin: 0px 6px;
					@include transition($t1);
					opacity: 1;
				svg path{
					@include transition($t1)
				}
				
				&:hover {
				opacity: 0.6;
					
				}
			}
			
		}
			
		.showWeek{
			position: absolute;
			right:30px;
			top:-10px;
			background-color:$LinkColor;
			text-transform: uppercase;
			color:#FFFFFF;
			padding:10px 20px;
			font-weight: 100;
		}
		.navInner {
			width:calc(100% - 40px);
			/*max-width:1280px;*/
			margin:20px;
			position:relative;
			display:flex;

			.inner.primary {
				width:100%;
				@include order(2)
				@include transition($t3)
				@include transition-timing-function(cubic-bezier(1, 0.01, 0.06, 1))
				&.hidden {
					@include transform(translateY(-100px))
					pointer-events:none;
				}
			}
			.headerLogo {
				position: relative;
				width:400px;
				height:180px;
				display:inline-block;
				margin-left:20px;
				@include order(1)
			}
			.headerControls {
				width:50%;
				position:absolute;
				right:30px;
				top:10px;
				display: inline-block;
				text-align:right;
				
			}
			#desktopNavContainer {
				text-align:right;
				
				#desktopNav {
					display: inline-block;
					text-align:right;    
					padding-right: 10px;
					.topLevel{
						position: relative;
						padding:0 20px;
						display: inline-block;
						/*border-right:2px solid $LinkColor;*/
						&:last-child{
							border-right:none;
						}
						&>a {
							color: #FFFFFF;
							text-transform: uppercase;
						/*	font-weight:100;*/
						}
						.subMenu {
							padding-top:20px;
							position: absolute;
							left:50%;
							bottom:0;
							width:300px;
							display:none;
							font-size: 0.8em;
							@include transform(translateX(-50%) translateY(100%))
							z-index:4;
							.parent {
								width:100%;
								
								padding: 0;
								
								background-color:rgba($textGrey, 0.95);
								border-top:5px solid $LinkColor;
								&:before {
									content:'';
									position: absolute;
									left:50%;
									top:0;
									@include transform(translateX(-50%) translateY(10px))
									width:20px;
									height:10px;
									background-color:$LinkColor;
									/*background-image: url(../images/dropdownTriangle.svg);*/
									@include clip-path(polygon(50% 0%, 100% 100%, 0% 100%));
									background-size:cover;
									background-position: center top;
									
									overflow:hidden;
								}
								.stack {
									text-align:left;
									border-bottom:1px solid rgba(164, 163, 163, 0.74);
									a {
										color:#FFFFFF;
										
										padding:10px 20px;
										display:block;
										@include transition($t1)
										position: relative;
										font-weight:100;
										&:before {
											content:'';
											position: absolute;
											left:20px;
											top:50%;
											width:0px;
											height:5px;
											background-color:$LinkColor;
											@include transition($t1)
											@include transform(translateY(-50%))
										}
										&:hover {
											padding:10px 20px 10px 50px;
											color:$LinkColor;
											&:before{
												width:20px;
											}
										}
									}
									.children {
										a {
											display:block;
										}
									}
									&:last-child {
										border-bottom:none;
									}
								}
							}
						}
						&:hover {
							.subMenu{
								display:inline-block;
							}
						}
					}
				}
			}
		}
	}
	.pageHeader {
		background-color: #f4f4f4;
		width: 100%;
		height: 500px;
	}
	.flexslider {
		overflow:hidden;
		height:100%;
		ul {
			overflow:hidden;
			height:100%;
			li {
				overflow:hidden;
				position:relative;
				height:100%;
				.slideBG {
					background-position:center center;
					background-size:cover;
					margin-top: 0 !important;
					height:100%;
					position: relative;

				}
				.slideTitle {
					position: absolute;
					left:50%;
					/*top:50%;*/
					top: 43%; //60%;
					@include transform(translateX(-50%) translateY(-50%));
					color:#FFFFFF;
				/*	font-size:40px;*/
					font-size: 68px;
					font-weight:700;
					text-transform: uppercase;
					z-index:2;
					@include text-shadow(3px 3px 7px #000000);
					width:100%;
					text-align:center;
				}
			}
		}
	}
	.callToAction {
		position: absolute;
		display: inline-block;
		/*padding: 22px 60px 20px 60px;*/
		padding: 12px 30px 10px 30px;
		color:#FFFFFF;
		text-transform:uppercase;
		border:1px solid #FFFFFF;
		top:calc(43% + 100px);
		left:50%;
		@include transform(translateX(-50%) translateY(-50%))
		@include transition($t1)
		z-index:2;
		font-weight:100;
		&:hover {
			background-color:#FFFFFF;
			color:$LinkColor;
		}
	}
	.headerBottom {
		position: absolute;
		left:0;
		bottom:0;
		display: flex;
		width:100%;
	}
	h1 {
		display: block;
		color:#000000;
		padding-bottom:10px;
		margin-bottom: 60px;
		@include text-shadow(3px 3px 3px rgba(#000000,0.4))
		&.pageTitle {
			text-align:center;
			color:#FFFFFF;
			@include transform(translateX(-50%) translateY(-50%))
			z-index:2;
			position: absolute;
			top:70%;
			left:50%;
			width:100%;
			text-transform:uppercase;
		}
	}
}

.searchIcon{
	margin-left: 29px !important;
}
