.centerColumn {
	.centerRight {
		.clubsWrapper {
			display: block;
			
			position: relative;
			
			.clubsItem {
				width:calc(50% - 10px);
				position: relative;
				display: inline-block;
				float:left;
				height:300px;
				&:nth-child(odd){
					margin:0 10px 20px 0;
				}
				&:nth-child(even){
					margin:0 0px 20px 10px;
				}
				
				.clubsImage{
					position: absolute;
					left:0;
					top:0;
					width:100%;
					height:100%;
					background-size:cover;
					background-position:center center;
					z-index:1;
					&:after {
						content:'';
						position: absolute;
						left:0;
						top:0;
						width:100%;
						height:100%;
						background-color:rgba(0,0,0,0.3);
					}
				}
				.clubsTitle {
					position: absolute;
					top:50%;
					left:40px;
					@include transform(translateY(-50%))
					z-index:2;
					color:#FFFFFF;
					font-weight: bold;
					text-transform: uppercase;

				}
				.viewClub {
					position: absolute;
					top:calc(50% - 40px);
					left:80px;
					@include transform(translateY(-50%))
					z-index:2;
					color:$LinkColor;
					font-size:16px;
					opacity:0;
					@include transition($t1);
					text-transform:uppercase;
				}
				
				&:before {
					content:'';
					position: absolute;
					left:20px;
					top:20px;
					width:calc(100% - 44px);
					height:calc(100% - 44px);
					border:2px solid $LinkColor;
					z-index:2;
					opacity:0;
					@include transition($t1)
					pointer-events:none;
				}
				&:hover {
					&:before {
						opacity:1;
					}
                    .viewClub {
                        left:40px;
                        opacity:1;
                    }
				}
			}
		}
		.clubsOverlayWrapper {
			position: fixed;
			left:0;
			top:0;
			width:100%;
			height:100%;
			background-color:rgba(0,0,0,0.6);
			z-index:5;
			pointer-events:none;
			opacity:0;
			@include transition($t1)
			&.active {
				opacity:1;
				pointer-events:all;
			}
			.clubsOverlaySliderOuter {
				position: absolute;
				left:50%;
				top:50%;
				@include transform(translateX(-50%) translateY(-50%));
				width:calc(100% - 40px);
				max-width:1280px;
				.clubsOverlaySlider {
					margin:0 auto;
					height:100%;
					.clubsOverlayItem {
						background-color:$LinkColor;
						width:calc(100% - 0px);
						max-width:1280px;
						height:calc(100% - 0px);
						display:flex;
						position: relative;
						.clubsOverlayClose {
							position: absolute;
							top:20px;
							right:20px;
							width:20px;
							height:20px;
							background-image:url(../images/overlayClose.svg);
							background-size:contain;
							background-position:center center;
							background-repeat: no-repeat;
						}
						&.active {
							opacity:1;
							pointer-events:all;
						}
						.clubsOverlayImage {
							width:50%;
							background-size:cover;
							background-position:center center;
						}
						.clubsOverlayContent {
							width:calc(50% - 120px);
							padding:60px;
							color:#FFFFFF;
							a{
								&:hover{
									color: white;
									text-decoration: none;
								}
							}
							.Title {
								color:#FFFFFF;
								text-transform: uppercase;
								margin-bottom:40px;
								font-weight:100;
								display: block;
								font-size:26px;
								font-weight:100;
							}
							.Content {
								height:250px;
								color:#FFFFFF;
								margin-bottom:40px;
								font-weight:100;
								display:inline-block;
								line-height:25px;
								text-align: justify;
							}
							.School, .Event {
								color:#FFFFFF;
								margin-bottom:10px;
								font-weight:100;
								font-size:12px;
								display:block;
								strong {
									color:#FFFFFF;
									text-transform:uppercase;
								}
							}
							.Supervisors {
								color:#FFFFFF;
								font-weight:100;
								margin:30px 0 10px 0;
								text-transform:uppercase;
								font-size:12px;
							}
							.Location {
								color:#FFFFFF;
								font-weight:100;
								margin:30px 0 10px 0;
								text-transform:uppercase;
								font-size:12px;
								.locationIcon {
									display: inline-block;
									margin:0 10px 0 0;
									width:12px;
									height:12px;
								}
							}
							.startTime {
								color:#FFFFFF;
								font-weight:100;
								margin:0 0 10px 0;
								text-transform:uppercase;
								font-size:12px;
								.timeIcon {
									display: inline-block;
									margin:0 10px 0 0;
									width:12px;
									height:12px;
								}
							}
							.overlayButtons {
								margin-top:40px;
								.overlayButton {
									display: inline-block;
									padding:10px 30px;
									border:2px solid #FFFFFF;
									margin:0 20px 20px 0;
									color:#FFFFFF;
									text-transform: uppercase;
									font-weight:100;
									font-size:12px;
									float:left;
								}
							}
						}
					}
				}
				.clubsOverlayControl {
					position: absolute;
					top:50%;
					width:20px;
					height:25px;
					background-size: contain;
					background-position:center center;
					background-repeat: no-repeat;
					cursor:pointer;
					&#overlayPrev {
						left:0;
						@include transform(translateX(-100%) translateY(-50%))
						background-image:url(../images/overlayPrev.svg);
					}
					&#overlayNext {
						right:0;
						@include transform(translateX(100%) translateY(-50%))
						background-image:url(../images/overlayNext.svg);
					}
					a {
						position: absolute;
						left:0;
						top:0;
						width:100%;
						height:100%;
					}
				}
			}
			
			
		}

	}

}