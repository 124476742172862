/*
|===============================================================
|	Homepage News Widget
|===============================================================
*/

.homepageNewsSliderWrapper {
	background-color: $veryLightGrey;
	padding: 0px 30px;
	position:relative;
	box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	@include transition($t1);
	.titleWrapper {
		max-width:1280px;
		margin:0 auto;
		position: relative;
		.newsWidgetTitle {
			margin: 90px 0 81px 0;
			display:block;
			.newsWidgetTitleTop {
				color:$darkBlue;
				text-transform:uppercase;
				font-size:30px;
				text-align:center;
				font-weight:600;
			}
			.newsWidgetTitleBottom {
				color:$lightGrey;
				text-transform:uppercase;
				font-size:50px;
				text-align:center;
				font-weight:600;
			}
		}
	}
	.viewMoreBtn {
		padding:10px 30px;
		background-color:red;
		border-radius:10px;
		color:$LinkColor;
		display:inline-block;
		font-weight:bold;
		@include transition($t1);
		&:hover {
			background-color:$LinkColor;
			color:#FFFFFF;
		}
	}
	.outer {
		max-width:1280px;
		margin:0px auto 30px auto;
		.homepageNewsSlider {
			width:100%;
			/**/
			margin:0 auto;
			display:flex;
			z-index:200;
			.slide {
				z-index:1;
				width:100%;
				position: relative;
				margin:0 20px;
				/*height:572px;*/
				background-color:#FFFFFF;
				.newsArticleImage {
					width:100%;
					height:250px;
					background-position: center center;
					background-size:cover;
					position: relative;
					background-repeat:no-repeat;
					&:after {
						content:'';
						position: absolute;
						left:0;
						bottom:0;
						width:100%;
						height:10px;
						background-color:rgba($LinkColor,1);
						@include transition($t1)
					}
				}
				.inner {
					/*margin:20px;*/
					/*border:2px solid $lightGrey;*/
					padding: 40px 20px 20px 20px;
					/*height:100%;*/
					position:relative;
					float: left;
					
					.title {
						color:$darkBlue;
						font-weight:600;
						text-transform: uppercase;
						font-size: 18px;
						padding-bottom: 10px;
						/*height:60px;*/

					}
					.content {
						
					}
					.readMore {
						border:1px solid $LinkColor;
						padding: 12px 20px 10px 20px;
						display:inline-block;
						margin:10px 0 0 0;
						@include transition($t1);
						position:relative;
						color:$textGrey;
						font-weight:100;
						font-size:12px;
						z-index: 80;
						text-transform:uppercase;
						&:hover {
							background-color:$LinkColor;
							
							a, span {
								color:#FFFFFF;
							}
						}
					}
				}
				section {
					margin-bottom:60px;
					p {
						text-align:justify;
					}
				}
				&:hover {
					.newsArticleImage {
						&:after {
							height:100%;
							background-color:rgba($LinkColor,0.6);
						}
					}
				}
				
			}
		}
		.allNewsWrapper {
			text-align:center;    
			padding-top: 25px;
			.allNews {
				display: inline-block;
				padding:20px 60px;
				background-color:$darkBlue;
				color:#FFFFFF;
				text-transform: uppercase;
				margin:60px auto;
				@include transition($t1)
				&:hover {
					background-color:$LinkColor;
				}
			}
		}
		
	}
	.widgetNewsPrev, .widgetNewsNext {
		position: absolute;
		top:10px;
		display:inline-block;
		width:40px;
		height:40px;
		text-align:center;
		border:1px solid $lightGrey;
		z-index:8;
		background-size: 50% 50%;
		background-position: center center;
		background-repeat: no-repeat;
		@include transition($t1);
		a {
			color:$LinkColor;
			width: 100%;
		    height: 100%;
		    display: block;
		}
		&:hover {
			background-color:$LinkColor;
			border:1px solid $LinkColor;
		}
	}
	.widgetNewsPrev {
		right:70px;
		background-image: url(../images/leftBracketBlack.svg);
	}
	.widgetNewsNext {
		right:0px;
		background-image: url(../images/rightBracketBlack.svg);
	}
	&.hidden {
		opacity:0;
	}
}
