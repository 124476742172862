#modal {
	position: relative;
	width: calc(100% - 120px);
	
	
	align-items: center;
	
	z-index: 10;
	@include order(2)
	.modalWrapper {
		padding: 40px;
		width:calc(100% - 80px);
		/*max-width: 500px;*/

		background: rgba($LinkColor,0.75);
		padding: 40px;
		text-align:center;
		position: absolute;
		bottom:0;
		/*@include transform(translateY(-50%))*/
		h1 {
			color: #FFFFFF;
			margin:0;
			    font-size: 1.3em !important;
			@include text-shadow(none)
			text-align:left;
			text-transform: uppercase;
		}
		.modalBox {
			
			
			/*height: 300px;*/
			
			ul {
				li {
					height: 150px;
				}
			}
			
			h1, p, span {
				color: #FFFFFF;
				text-align: left;
				margin:0;
				padding-bottom:20px;
				font-weight:100;
				line-height:30px;
				
			}
			.findOutMore {
				padding: 12px 20px 10px 20px;
			    display: inline-block;
			    border: 1px solid #FFF;
			    color: #FFFFFF;
			    text-align: left;
			    text-transform: uppercase;
			    font-weight: 100;
			    font-size:12px;
			    /*@include transform(translateY(100%))*/
			    margin:20px 20px 0 0 ;
			    @include transition($t1)
			    a {
			    	color: #FFFFFF;
			    }
			    &:hover {
			    	color:$LinkColor;
			    	background-color:#FFFFFF;
			    	a {
			    		color:$LinkColor;
			    	}
			    }
			}
		}
		.bx-controls {
			position: absolute;
			right:40px;
			bottom:50px;
		

			.bx-pager {
				width: 100%;
				text-align: right;
				margin-top:40px;
				.bx-pager-item {
					position: relative;
					display: inline-block;
					margin: 0 5px;
					a.bx-pager-link {
						position: relative;
						display:block;
						width: 10px;
						height: 10px;
						border-radius: 50%;
						text-indent: -9999px;
						background: #FFFFFF;
						opacity: 1;
						color:transparent;
						&:after {
							content:'';
							position: absolute;
							left:50%;
							top:50%;
							@include transform(translateX(-50%) translateY(-50%))
							width:1px;
							height:1px;
							border:1px solid #FFFFFF;
							border-radius:50%;
							@include transition($t1)
						}
					}
					a.bx-pager-link.active {
						&:after {
							width:calc(100% + 4px);
							height:calc(100% + 4px);
						}
					}
				}
			}
		}

		.close {

			padding:10px 30px;
			text-align:center;
			display:block;
			border:2px solid $LinkColor;
			margin:30px auto 0 auto;
			background-color:transparent;
			color:$LinkColor;
			text-transform: uppercase;
			@include transition($t1);
			cursor:pointer;
			&:hover {
				background-color:$LinkColor;
				color:#000000;
			}
		}
	}
	
}

.priorityAlert {
	position:absolute;
	-webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    left:50%;
    top:50%;
    width:30%;
    min-width:240px;
    padding:40px;
    text-align: center;
    font-size:30px;
    background-color:$LinkColor;
    line-height:40px;
    color:#FFFFFF;
    z-index:1;
    .priorityAlert a {
		color:#FFFFFF;
	}

	.priorityAlertClose {
		display:block;
		width:100%;
		color:#FFFFFF;
		font-size:16px;
		line-height:40px;
		margin-top:30px;
		cursor:pointer;
	}
}

.priorityAlert a {
	color:#FFFFFF;
}

.priorityAlertClose {
	display:block;
	width:100%;
	color:#FFFFFF;
	font-size:16px;
	line-height:40px;
	margin-top:30px;
	cursor:pointer;
}
