.keyFacts {
	display: block;
	padding: 0px 0 0 0;
	position: relative;
	.keyFactsTitle {
		margin:90px 0 60px 0;
		display:block;
		.keyFactsTitleTop {
			color:$darkBlue;
			text-transform:uppercase;
			font-size:30px;
			text-align:center;
			font-weight:600;
		}
		.keyFactsTitleBottom {
			color:$lightGrey;
			text-transform:uppercase;
			font-size:50px;
			text-align:center;
			font-weight:600;
		}
	}
	.keyFactsInner {
		text-align:center;
		display:block;
		padding-bottom: 258px;
		.keyFactsItem {
			min-width:200px;
			margin:0 20px 40px 20px;
			display: inline-block;
			@include transition($t1)
			@include transform(scale(0,0))
			opacity:0;
			vertical-align: top;
			&:nth-child(4n+2){
				@include transition-delay(0.2s)
			}
			&:nth-child(4n+3){
				@include transition-delay(0.4s)
			}
			&:nth-child(4n+4){
				@include transition-delay(0.6s)
			}

			.keyFactsNumber {
				color:$darkBlue;
				text-align:center;
				font-size:60px;
				font-weight:600;
				text-transform:uppercase;
				border-bottom:2px solid $LinkColor;
				padding-bottom: 10px;
    			margin-bottom: 20px;
			}
			.keyFactsItemTitle {
				color:$textGrey;
				text-align:center;
				font-size:20px;
				font-weight:600;
				text-transform:uppercase;
			}

		}
		&.keyFactsActive .keyFactsItem {
			@include transform(scale(1,1))
			opacity:1;
		}
	}
	.skylineForeground {
		/*opacity:0.08;*/
		position: absolute;
		left:0;
		bottom:0;
		width:100%;
		z-index:5;
		margin-top:60px;
		svg {
			vertical-align: bottom;
		}
	}
}