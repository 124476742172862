@media all and (max-width: 1500px) {
    .centerColumn {
        .centerRight {
            table {
                tr {
                    th,
                    td {
                        padding: 20px 8px;
                    }
                }
            }
        }
    }
}

@media all and (max-width: 1400px) {
    header nav.main .navInner #desktopNavContainer #desktopNav .topLevel {
        padding: 0 10px !important;
    }
}

@media all and (max-width: 1350px) {
    .centerColumn {
        .centerRight {
            .clubsOverlayWrapper {
                .clubsOverlaySliderOuter {
                    .clubsOverlayControl {
                        width: 40px !important;
                    }
                    .clubsOverlayControl#overlayNext {
                        right: 35px !important;
                    }
                    .clubsOverlayControl#overlayPrev {
                        left: 35px !important;
                    }
                }
            }
        }
    }
}

@media all and (max-width: 1280px) {
    .featuredNewsItem {
        width: 33.33% !important;
        float: left;
        min-height: 250px;
    }
    .featuredNewsItem:first-of-type {
        width: 50% !important;
    }
    .featuredNewsItem:nth-child(2) {
        width: 50% !important;
    }
    .featuredArticleWrapper .articleHolder {
        display: block;
    }
}
@media all and (max-width: 978px) {
    .featuredNewsItem {
        width: 50% !important;
        float: left;
    }
    .featuredNewsItem:last-of-type {
        width: 100% !important;
    }
}
@media all and (max-width: 700px) {
    .featuredNewsItem:first-of-type,
    .featuredNewsItem:nth-child(2),
    .featuredNewsItem {
        width: 100% !important;
    }
    .centerColumn .centerRight .socialLinksWrapper .socialLinks {
        bottom: 0px;
    }
}
@media all and (max-width: 1280px) {
    header {
        nav.main .navInner {
            display: block !important;
        }
        .headerBottom {
            display: block !important;
            #modal {
                width: 100% !important;
                .modalWrapper {
                    /*@include transform(translateY(-100%) !important)*/
                }
            }
            #quickLinks {
                padding-bottom: 0px !important;
            }
        }
    }
    .centerColumn {
        .centerRight {
            padding: 0 20px 60px 20px !important;
            width: calc(75% - 20px) !important;
        }
    }
    .historyWrapper {
        .historyObject {
            width: 25vw !important;
            &.keyDate {
                width: 50vw !important;
            }
        }
    }
}

@media all and (max-width: 1150px) {
    .centerColumn {
        .centerRight {
            .quicklinksWrapper {
                #quickLinks {
                    .titleText {
                        //padding:20px 20px !important;
                    }
                }
            }
        }
    }
    .homepageFooter {
        .footerTop {
            flex-direction: column;
            .footerTopLeft {
                min-height: 150px;
            }
            .footerTopRight {
                text-align: center !important;
                padding: 20px 0 !important;
            }
        }
    }
}

@media all and (max-width: 1024px) {
    #MenuIcon {
        display: inline-block !important;
    }
    #ChildPageButtons {
        display: block;
    }
    header {
        nav.main {
            .headerTop {
                text-align: center !important;
                #usefulLinks {
                    display: block !important;
                    margin: 10px 0;
                }
            }
            .navInner {
                display: flex !important;
                .inner.primary {
                    #desktopNavContainer {
                        display: none !important;
                    }
                }
            }
        }
    }
    .homepageNewsSliderWrapper {
        .outer {
            .homepageNewsSlider {
                display: block !important;
                .slide {
                    display: inline-block !important;
                    &:nth-child(1) {
                        width: calc(50% - 20px);
                        margin: 0 20px 0 0;
                        float: left;
                    }
                    &:nth-child(2) {
                        width: calc(50% - 20px);
                        margin: 0 0 0 20px;
                        float: left;
                    }
                    &:nth-child(3) {
                        width: 100%;
                        margin: 20px 0 0 0;
                    }
                }
            }
        }
    }
    .centerColumn {
        .centerRight {
            width: calc(100% - 40px) !important;
            padding: 0 20px !important;
        }
        .centerLeft {
            display: none !important;
        }
    }
    .historyWrapper {
        .historyObject {
            width: calc(100vw / 3) !important;
            &.keyDate {
                width: calc(100vw * (2 / 3)) !important;
            }
            .historyObjectInfo {
                .year {
                    margin: 0 0 10px 0 !important;
                    font-size: 30px !important;
                }
            }
        }
    }
}

@media all and (max-width: 1000px) {
    .clearLarge {
        width: 100% !important;
        clear: both;
    }
}
@media all and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media all and (max-width: 768px) {
    header {
        nav.main {
        }
    }
    #quickLinks.noticesVisible {
        padding-bottom: 0 !important;
    }
    .showcaseEventOverlayWrapper .showcaseEventOverlayItem {
        width: calc(100% - 90px);
    }
    .showcaseEventOverlayItem .showcaseEventOverlayContent {
        padding: 60px 20px;
    }

    .quicklinksWrapper {
        #quickLinks {
            display: block !important;
            text-align: left !important;
            a {
                width: calc(50% - 3px) !important;
                display: inline-block !important;
                &:after {
                    width: 0px !important;
                }
                .titleText {
                    width: 100% !important;
                }
            }
        }
    }

    .homepageNewsSliderWrapper {
        .outer {
            .homepageNewsSlider {
                .slide {
                    display: inline-block !important;
                    width: 100% !important;
                    margin: 0 0 20px 0 !important;
                }
            }
        }
    }

    #MobileNavigation {
        width: 100%;
        right: -100%;
    }
    #SearchForm_SearchForm fieldset {
        #SearchForm_SearchForm_Search {
            font-size: 30px;
            width: 240px !important;
        }
        #SearchForm_SearchForm_action_results {
            width: 30px;
            height: 30px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .historyWrapper {
        .historyObject {
            width: 100% !important;
            &.keyDate {
                width: 100% !important;
            }
        }
    }
}

@media all and (max-width: 640px) {
    #homepage-events {
        .widgetsWrapper {
            width: 100% !important;
            .sliderControls {
                #eventsNext,
                #eventsPrev {
                    top: 10px !important;
                }
                #eventsPrev {
                    left: 0 !important;
                    @include transform(translateX(0) !important);
                }
                #eventsNext {
                    right: 0 !important;
                    @include transform(translateX(0) !important);
                }
            }
        }
    }
    body.GalleryHolder {
        .centerColumn {
            .centerRight {
                .galleryWrapper {
                    a {
                        &:nth-child(odd),
                        &:nth-child(even) {
                            .galleryItem {
                                width: 100% !important;
                                margin: 0 0 20px 0 !important;
                                .galleryItemInner {
                                    padding: 20vw 60px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width: 540px) {
    .centerColumn {
        .centerRight {
            .quicklinksWrapper {
                #quickLinks {
                    a {
                        width: 100% !important;
                        display: block !important;
                    }
                }
            }
        }
    }
}

@media all and (max-width: 450px) {
    .fancybox-title-float-wrap .child .titleWrapper a {
        font-size: 8px;
    }
    .fancybox-title-float-wrap .child .titleWrapper a:nth-child(2) {
        margin-left: 0;
    }
    #SearchForm_SearchForm fieldset {
        #SearchForm_SearchForm_Search {
            font-size: 30px;
        }
        #SearchForm_SearchForm_action_results {
            width: 30px;
            height: 30px;
            margin-top: 15px;
        }
    }
    .tacSmall {
        text-align: center !important;
    }
    .hiddenSmall {
        display: none !important;
    }
}

.ClubsPage {
    header nav.main {
        z-index: 1;
    }
}

@media all and (max-width: 1024px) {
    input#SearchForm_SearchForm_Search {
        background-color: #343c44 !important;
        padding: 10px 20px !important;
        box-sizing: border-box !important;
        -o-box-sizing: border-box !important;
        -ms-box-sizing: border-box !important;
        -moz-box-sizing: border-box !important;
        -webkit-box-sizing: border-box !important;
    }
    .searchClose {
        display: none;
    }
    .searchHolder {
        display: none;
        right: 0px;
        top: -21px;
    }
    #SearchForm_SearchForm fieldset #SearchForm_SearchForm_Search {
        font-size: 13px;
        width: 240px !important;
        position: absolute;
        right: 60px;
        border-radius: 0;
        margin-top: -1px;
    }
    .searchHolder.show {
        display: block;
    }
    .ChildPageButtons {
        display: inline-block;
    }
    header nav.main .navInner .inner.primary.hidden {
        -webkit-transform: translateY(0%) !important;
        -moz-transform: translateY(0%) !important;
        -ms-transform: translateY(0%) !important;
        transform: translateY(0%) !important;
    }

    .centerColumn
        .centerRight
        .clubsOverlayWrapper
        .clubsOverlaySliderOuter
        .clubsOverlayControl#overlayNext {
        right: 40px !important;
    }
    .centerColumn
        .centerRight
        .clubsOverlayWrapper
        .clubsOverlaySliderOuter
        .clubsOverlayControl#overlayPrev {
        left: 40px !important;
    }
}

@media all and (max-width: 1040px) {
    .newsLetterWrapper .formWrapper #Form_NewsLetterForm .Actions,
    .newsLetterWrapper .formWrapper #Form_NewsLetterFormFrench .Actions {
        height: 57px;
        display: inline-block;
        vertical-align: middle;
    }
    input#Form_NewsLetterFormFrench_Inscrivez {
        height: 57px;
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        @include border-radius(0px !important);
    }
    .instagramWidget {
        display: block;
        .instagramItem {
            width: 33.33%;
            float: left;
        }

        .instagramItem:last-of-type {
            width: 50%;
        }
        .instagramItem:nth-child(4) {
            width: 50%;
        }
    }
    .instagramWidget .instagramItem {
        height: 300px;
    }
    .newsLetterWrapper {
        padding: 60px 10px;
    }

    header .headerBottom {
        position: relative !important;
        /* left: 0; */
        /* bottom: 0; */
        display: block !important;
        width: 100% !important;
        float: left !important;
    }
    #modal {
        position: relative !important;
        float: left !important;
    }
    #modal .modalWrapper {
        position: relative !important;
        float: left !important;
    }
    #modal .modalWrapper {
        background: rgba(217, 34, 64, 1) !important;
    }

    #headerLinks {
        bottom: 10px;
    }
    header .flexslider ul li .slideTitle {
        font-size: 20px !important;
    }
    #modal .modalWrapper h1 {
        font-size: 1.5em;
    }
    .homepageFooter
        .footerMiddle
        .footerMiddleLeft
        .footerLinksWrapper
        .homepageFooterLinksItem {
        margin: 5px !important;
    }
    .footerLinksWrapper {
        margin-bottom: 20px !important;
    }
}

@media all and (min-width: 1041px) and (max-width: 1280px) {
    header {
        .headerBottom {
            position: relative !important;
        }
    }
}

@media all and (max-width: 740px) {
    .instagramWidget {
        .instagramItem {
            width: 50%;
            float: left;
        }
        .instagramItem:last-of-type {
            width: 100%;
        }
    }

    .newsLetterWrapper
        .formWrapper
        #Form_NewsLetterForm
        fieldset
        #Form_NewsLetterForm_Sign_up_for_our_newsletter_Holder
        .middleColumn
        input,
    .newsLetterWrapper
        .formWrapper
        #Form_NewsLetterForm
        fieldset
        #Form_NewsLetterFormFrench_Inscrivez_Holder
        .middleColumn
        input,
    .newsLetterWrapper
        .formWrapper
        #Form_NewsLetterFormFrench
        fieldset
        #Form_NewsLetterForm_Sign_up_for_our_newsletter_Holder
        .middleColumn
        input,
    .newsLetterWrapper
        .formWrapper
        #Form_NewsLetterFormFrench
        fieldset
        #Form_NewsLetterFormFrench_Inscrivez_Holder
        .middleColumn
        input {
        width: 100%;
    }
    .newsLetterWrapper .title {
        margin-bottom: 20px;
    }
    .newsLetterWrapper .formWrapper {
        margin: 0 10px;
    }
}

@media all and (max-width: 480px) {
    .instagramWidget {
        .instagramItem {
            width: 100% !important;
            float: left;
        }
        .instagramItem:last-of-type {
            width: 100%;
        }
    }
}

@media all and (max-width: 840px) {
    header .headerBottom #quickLinks {
        display: block;
        a {
            display: block;
            padding: 10px 20px;
            float: left;
            clear: both;
        }
    }
    .homepageNewsSliderWrapper
        .titleWrapper
        .newsWidgetTitle
        .newsWidgetTitleBottom,
    .resultsWidget .titleWrapper .resultsWidgetTitle .resultsWidgetTitleBottom,
    .homepageEventsSliderWrapper
        .titleWrapper
        .eventsWidgetTitle
        .eventsWidgetTitleBottom,
    #homepage-events .titleWrapper .eventsWidgetTitle .eventsWidgetTitleBottom,
    .keyFacts .keyFactsTitle .keyFactsTitleBottom,
    .ofstedWidget .titleWrapper .ofstedWidgetTitle .ofstedWidgetTitleBottom,
    .clubsWidget .titleWrapper .clubsWidgetTitle .clubsWidgetTitleMiddle {
        font-size: 30px !important;
    }
    .newsLetterWrapper a.allNewslettersLink {
        padding: 22px 10px;
    }
    .ButtonVacancies,
    a.ChildPageButton {
        width: 86%;
    }
}
@media all and (max-width: 768px) {
    .keyFacts .keyFactsInner {
        @include transform(translateY(0px) !important);
    }
}

@media all and (max-width: 768px) {
    header nav.main .navInner .headerLogo {
        height: 80px !important;
        margin-left: 0px !important;
        width: auto !important;
    }
}

@media all and (max-width: 660px) {
    .homepageFooter .footerMiddle,
    .homepageFooter .footerTop {
        display: block !important;
        text-align: center;
        * {
            display: inline-block;
            text-align: center !important;
            vertical-align: middle;
        }
    }
    .homepageFooterLinksItem {
        margin: 10px !important;
        width: 180px !important;
    }

    .footerTopRight {
        padding-left: 0px !important;
    }
    .phoneNumber,
    span.addressText {
        border: none !important;
        padding: 0px !important;
    }

    .centerColumn .centerRight h4 {
        font-size: 2em !important;
    }
    .footerMiddleCenter {
        display: none !important;
    }

    .newsLetterWrapper .title {
        font-size: 15px;
    }
    .homepageFooter .footerTop .footerTopLeft {
        /*width: 170px !important;*/
        width: 230px !important;
    }

    .centerColumn .centerRight .clubsWrapper .clubsItem {
        width: 100% !important;
        margin: 5px 0px !important;
    }
}

/*** Staff Responsive ***/
@media all and (max-width: 520px) {
    .StaffPage .staffProfile .profilePic {
        margin: 0 0px 30px 0 !important;
        float: left;
        width: 100%;
        clear: both;
        display: block;
    }
}
/*** Staff Responsive ***/
@media all and (max-width: 740px) {
    .showcaseEventWrapper .showcaseEventItem.item-6,
    .showcaseEventWrapper .showcaseEventItem.item-1 {
        width: calc(100vw - 12px) !important;
        height: 300px !important;
    }
    .showcaseEventWrapper .showcaseEventItem,
    .showcaseEventWrapper .showcaseEventItem.item-2,
    .showcaseEventWrapper .showcaseEventItem.item-3 {
        width: calc(50vw - 6px) !important;
        height: 300px !important;
    }
}

.clubsOverlayWrapper,
.showcaseEventOverlayWrapper {
    z-index: 9999 !important;
}

.CourseMap .centerRight {
    svg {
        height: auto;
    }
}

@media all and (max-width: 980px) {
    .contactFormWrapper {
        display: block !important;
        float: left;
        clear: both;
        width: 100%;
    }

    body.ContactForm .contactFormWrapper .contactFormLeft {
        max-width: 100%;
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    body.ContactForm .contactFormWrapper .contactFormLeft .Region {
        min-width: 0px;
    }

    .mapBox {
        width: 100% !important;
        float: left;
        clear: both;
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    .homepageFooter {
        width: 100% !important;
        float: left;
        clear: both;
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    .contactFormLeft,
    .contactFormRight {
        width: 100% !important;
        float: left;
        clear: both;
        padding: 20px !important;
        * {
            max-width: 100%;
            box-sizing: border-box;
            -o-box-sizing: border-box;
            -ms-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
        }
    }
    body.ContactForm .contactFormWrapper .contactFormRight {
        max-width: 100%;
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    body.ContactForm .contactFormWrapper .contactFormRight .userform .field {
        float: left;
        clear: both;
        width: 100%;
    }
    body.ContactForm
        .contactFormWrapper
        .contactFormRight
        .userform
        .field
        .text
        .middleColumn
        input.text,
    body.ContactForm
        .contactFormWrapper
        .contactFormRight
        .userform
        .field
        .text
        .middleColumn
        textarea.textarea,
    body.ContactForm
        .contactFormWrapper
        .contactFormRight
        .userform
        .field
        .textarea
        .middleColumn
        input.text,
    body.ContactForm
        .contactFormWrapper
        .contactFormRight
        .userform
        .field
        .textarea
        .middleColumn
        textarea.textarea {
        width: 100% !important;
        max-width: 100% !important;
    }
}
@media all and (max-width: 840px) {
    .centerColumn .centerRight .contentObject .contentTabWrapper {
        display: block;
    }

    .vacancyItem {
        width: 100% !important;
        margin: 10px 0px !important;
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    header .headerBottom #quickLinks a {
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    .keyFacts .keyFactsTitle .keyFactsTitleTop {
        clear: both;
        padding-top: 20px;
    }

    .homepageEventsSliderWrapper #sliderUpcoming section .sliderContent,
    #homepage-events #sliderUpcoming section .sliderContent {
        display: block !important;
        width: 100% !important;
        margin: 10px 0px !important;
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        * {
            box-sizing: border-box;
            -o-box-sizing: border-box;
            -ms-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
        }
    }
    .homepageEventsSliderWrapper #sliderUpcoming section .sliderContent,
    #homepage-events #sliderUpcoming section .sliderContent {
        background-color: #fff !important;
    }
    .homepageEventsSliderWrapper
        #sliderUpcoming
        section
        .sliderContent
        .contentHolder,
    #homepage-events #sliderUpcoming section .sliderContent .contentHolder {
        width: 100% !important;
    }
    .homepageEventsSliderWrapper
        #sliderUpcoming
        section
        .sliderContent
        .dateHolder,
    #homepage-events #sliderUpcoming section .sliderContent .dateHolder {
        width: 100% !important;
        max-width: 100% !important;
    }

    .centerColumn
        .centerRight
        .contentObject
        .contentTabWrapper
        .contentTabObject {
        padding-bottom: 10px;
        margin-bottom: 10px !important;
        display: inline-block;
    }
    .clubsOverlayImage,
    .showcaseEventOverlayImage {
        display: none;
    }
    .centerColumn
        .centerRight
        .clubsOverlayWrapper
        .clubsOverlaySliderOuter
        .clubsOverlaySlider
        .clubsOverlayItem {
        height: auto !important;
    }
    .centerColumn .centerRight .clubsOverlayWrapper .clubsOverlaySliderOuter {
        top: 56% !important;
    }
    .ClubsOverlayWrapper,
    .showcaseEventOverlayWrapper {
        width: 100%;
        max-width: 100%;
    }
    .showcaseEventOverlayItem.active * {
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    .centerColumn
        .centerRight
        .clubsOverlayWrapper
        .clubsOverlaySliderOuter
        .clubsOverlaySlider
        .clubsOverlayItem
        .clubsOverlayContent,
    .showcaseEventOverlayWrapper
        .showcaseEventOverlayItem
        .showcaseEventOverlayContent {
        width: 100% !important;
        max-width: 100%;
        max-height: 100vh !important;
        overflow-x: auto;
    }

    /*	.historyWrapper .historyObject .historyObjectInfo .historyObjectInfoInner {
	    width: calc(100% - 85px) !important;
	}*/

    .quicklinksWrapper #quickLinks a .titleText {
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    .quicklinksWrapper #quickLinks a {
        width: 100% !important;
        display: inline-block !important;

        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    body.HistoryPage .quicklinksWrapper,
    body.ContactForm .quicklinksWrapper,
    body.GalleryPage .quicklinksWrapper {
        position: relative !important;
        float: left !important;
        clear: both !important;
        width: 100% !important;
        bottom: auto !important;
        left: auto !important;
        @include transform(translate(0%, 0%) !important);
    }
    .mapBox {
        float: left;
        clear: both;
    }
}

@media all and (max-width: 1200px) {
    .centerColumn {
        .centerRight {
            table {
                tr {
                    th,
                    td {
                        padding: 8px 10px;
                    }
                }
            }
        }
    }
    .titleOverlay {
        font-size: 0.75em;
    }
    .instagramWidget .instagramItem .titleOverlay .titleOverlayInner {
        top: 20%;
    }
    .instagramWidget .instagramItem .titleOverlay .titleOverlayInner .content {
        line-height: 19px;
    }
    .footerMiddleCenter {
        display: flex;
        align-items: flex-end;
    }
}
// @media all and (max-width: 1100px) {
//   .homepageFooterLinksItem {
//     min-width: 230px !important;
//   }
// }
@media all and (max-width: 1130px) {
    .historyWrapper .historyObject {
        width: calc(100vw / 3) !important;
        height: 50vw !important;
    }
}
@media all and (max-width: 850px) {
    .historyWrapper .historyObject {
        width: calc(100vw / 2) !important;
        height: 50vw !important;
    }
    .newsLetterWrapper .formWrapper #Form_NewsLetterForm,
    .newsLetterWrapper .formWrapper #Form_NewsLetterFormFrench {
        display: block;
    }
    .newsLetterWrapper .formWrapper {
        margin: 0px !important;
        padding: 0px 10px;
    }
    .newsLetterWrapper {
        width: 100%;
        float: left;
        clear: both;
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        * {
            width: 100%;
            float: left;
            clear: both;
            box-sizing: border-box;
            -o-box-sizing: border-box;
            -ms-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
        }
    }
}
@media all and (max-width: 460px) {
    .contentBoxContent {
        img {
            float: none !important;
            display: flex;
        }
    }
}

@media all and (max-width: 600px) {
    .centerColumn {
        .centerRight {
            padding: 0 20px !important;
            p {
                text-align: left;
            }
        }
    }
}

@media all and (max-width: 570px) {
    .titleWrapper {
        a {
            //display: none! important;
        }
    }

    .historyWrapper .historyObject {
        width: 100vw !important;
        max-width: 100% !important;
    }

    .historyWrapper .historyObject .historyObjectInfo .historyObjectInfoInner {
        width: 100% !important;
        max-width: 100% !important;
    }

    .historyWrapper .historyObject .historyObjectInfo {
        width: 100% !important;
        max-width: 100% !important;
        margin: 0px !important;

        * {
            box-sizing: border-box;
            -o-box-sizing: border-box;
            -ms-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            width: 100%;
            clear: both;
            text-align: center;
            display: block;
            position: relative;
        }
        .year {
            float: none !important;
            margin: 0 auto !important;
            margin-bottom: 10px !important;
        }
    }

    .historyWrapper .historyObject {
        height: auto !important;
        padding-top: 33vw !important;
    }

    .historyWrapper .historyObject .historyObjectInfo,
    .historyWrapper .historyObject .historyObjectInfo .historyObjectInfoInner {
        position: relative !important;
        width: 100% !important;
        max-width: 100% !important;
        clear: both !important;
        display: block !important;
        top: auto !important;
        left: auto !important;
        float: left !important;
        height: auto !important;
        @include transform(translate(0%, 0%) !important);
        * {
            float: left;
            clear: both;
            width: 100%;
        }
    }
    .historyWrapper .historyObject .historyObjectInfo,
    .historyWrapper .historyObject .historyObjectInfo .historyObjectInfoInner {
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    .historyWrapper .historyObject.keyDate .historyObjectInfo {
        position: relative !important;
        width: 100% !important;
        max-width: 100% !important;
        clear: both !important;
        display: block !important;
        top: auto !important;
        left: auto !important;
        float: left !important;
        height: auto !important;
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    .timelineWrapper {
        display: none;
    }
    .historyWrapper .historyObject .historyObjectImage {
        opacity: 0.4;
    }
}

@media all and (max-width: 900px) {
    .centerColumn {
        .centerRight {
            .linkedGalleryWrapper {
                .galleryItem {
                    .galleryItemInner {
                        padding: 90px 60px !important;
                    }
                }
            }
        }
    }
}

@media all and (max-width: 860px) {
    .centerColumn .centerRight .calendarWidgetFilter .inner {
        display: block;
    }
    .calendarWidget,
    .centerColumn
        .centerRight
        .calendarWidgetFilter
        .inner
        .calendarCategories {
        width: 100% !important;
        float: left;
        clear: both;
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    .centerColumn
        .centerRight
        .calendarWidgetFilter
        .inner
        .calendarCategories {
        padding: 20px 0px;
        border-left: none;
    }
}
@media all and (max-width: 560px) {
    .centerColumn .centerRight .eventsList .eventsListItem .viewEvent {
        padding: 30px 20px;
        font-size: 12px;
        text-align: center;
        white-space: normal;
    }
    .centerColumn .centerRight table tr th,
    .centerColumn .centerRight table tr td {
        padding: 20px 0px !important;
    }
    .fancybox-title-float-wrap .child .titleWrapper .titleLeft {
        display: none;
    }
    .news-teaser {
        width: 100% !important;
        clear: both;
        float: left;
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        margin: 20px 0px !important;
    }

    .galleryFrame .content .galleryImage {
        width: 100% !important;
        height: 200px !important;
        float: left;
        clear: both !important;
    }
    .showcaseEventOverlayContent,
    .clubsOverlayContent {
        padding: 30px !important;
        max-width: calc(100% - 98px);
        .Title {
            margin-top: 30px;
        }
    }
    .showcaseEventOverlayWrapper
        .showcaseEventOverlayItem
        .showcaseEventOverlayContent {
        padding: 14px !important;
    }
    .centerColumn .centerRight .calendarWidgetFilter .inner .calendarWidget {
        padding: 0px !important;
    }

    .centerColumn
        .centerRight
        .eventsList
        .eventsListItem
        .eventInfo
        .eventTitle {
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
}

@media all and (max-width: 550px) {
    .FAQtabWrapper {
        .FAQtabTitle {
            padding: 12px 0 !important;
        }
    }
    .centerColumn
        .centerRight
        .clubsOverlayWrapper
        .clubsOverlaySliderOuter
        .clubsOverlayControl#overlayNext {
        right: 32px !important;
        height: 20px;
    }
    .centerColumn
        .centerRight
        .clubsOverlayWrapper
        .clubsOverlaySliderOuter
        .clubsOverlayControl#overlayPrev {
        left: 32px !important;
        height: 20px;
    }
}

@media all and (max-width: 1430px) {
    .historyWrapper
        .historyObject
        .historyObjectInfo
        .historyObjectInfoInner
        .year {
        font-size: 24px !important;
    }
    .historyWrapper
        .historyObject
        .historyObjectInfo
        .historyObjectInfoInner
        .title {
        height: auto !important;
        font-size: 12px;
    }
    .historyWrapper
        .historyObject
        .historyObjectInfo
        .historyObjectInfoInner
        .year {
    }
}
@media all and (max-width: 700px) {
    .dibImg {
        float: none;
    }
    .FAQtabWrapper {
        .FAQtabTitle {
            margin-right: 20px !important;
        }
    }
    .centerColumn .centerRight .socialLinksWrapper .socialLinks {
        position: relative;
        margin-top: 30px;
        text-align: center;
    }
}
@media all and (max-width: 430px) {
    .showcaseEventWrapper .showcaseEventItem.item-6,
    .showcaseEventWrapper .showcaseEventItem.item-1 {
        width: calc(100vw - 2px) !important;
        height: 300px !important;
    }
    .showcaseEventWrapper .showcaseEventItem,
    .showcaseEventWrapper .showcaseEventItem.item-2,
    .showcaseEventWrapper .showcaseEventItem.item-3 {
        width: calc(100vw - 2px) !important;
        height: 300px !important;
    }
}

@media all and (max-width: 400px) {
    #SearchForm_SearchForm fieldset #SearchForm_SearchForm_Search {
        right: 55px;
    }
}

@media all and (max-width: 38px) {
    .fancybox-title-float-wrap .child .titleWrapper a {
        padding: 10px 10px;
    }
}
